<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h4>Domain Admins</h4>
    <button
      v-if="!readOnly"
      @click="openAddDomainAdminDialog"
      type="button"
      class="btn btn-sm btn-success ml-2"
    >
      <BIconPlusSquare /> Add
    </button>
  </div>
  <table class="table table-sm mt-2">
    <tbody>
      <tr v-if="domainAdminStore.domainAdminsForDomain(domainId).length === 0">
        <td colspan="2">No Domain Admins</td>
      </tr>
      <tr v-for="admin of domainAdminStore.domainAdminsForDomain(domainId)" :key="admin.user_id">
        <td>{{ admin.name }}</td>
        <td v-if="!readOnly" class="one-button">
          <button @click="openRemoveDomainAdminDialog(admin)" type="button" class="btn btn-sm btn-danger">
            <BIconTrashFill /> Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Add Domain Admin Modal -->
  <AModal
    v-model="addDomainAdminDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <h3>Add Domain Admin Privileges</h3>
    </template>
    <template #body>
      <div class="form-group search-fields">
          <label for="iam_id">IAM ID</label>
        <div class="input-group">
          <input
            v-model="newDomainAdminId"
            type="text"
            id="iam_id"
            name="iam_id"
            placeholder="IAM ID of new Domain Admin User"
            size="36"
            class="form-control">
          <button @click="requestNameFromIamId" class="btn btn-primary">Search <BIconSearch /></button>
        </div>
        <p v-if="uuidFormatErrorFound" class="text-danger">
          Formatting Error: Check that the format of your IAM ID is valid.
          {{ IamIdFormatErrorStr }}
        </p>
      </div>
      <!-- Don't bother showing the option to add an existing domain admin if there isn't anyone in the list -->
      <div v-if="domainId && existingDomainAdmins.length > 0" class="form-group search-fields">
        <label for="existing_user">Existing User</label>
        <select id="existing_user" class="form-control" v-model="newDomainAdminId" @change="setNameFromList">
          <option></option> <!-- Placeholder -->
          <option v-for="domainAdmin in existingDomainAdmins" :value="domainAdmin.iam_id" :key="domainAdmin.iam_id">
            {{ domainAdmin.name }}
          </option>
        </select>
      </div>
      <div class="form-group search-fields">
        <label for="newDomainAdminName">Name</label>
        <textarea
          id="newDomainAdminName"
          name="newDomainAdminName"
          v-model="newAdminName"
          disabled
          placeholder="Enter Users IAM ID and press Search Button."
          class="form-control"
        ></textarea>
      </div>
      <div class="form-group" :hidden=!showNameSetFields>
        <label for="cust_name">Temporary Name of New Domain Admin:</label>
        <div class="input-group">
          <input
              v-model="customName"
              type="text"
              id="cust_name"
              name="cust_name"
              placeholder="Name of Domain Admin"
              class="form-control">
        </div>
        <p class="text-muted">
          This name will just be used until the specified user logs in to AMPS for the first time, at which point
          the name associated with their credentials will take its place.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddDomainAdminDialog" class="btn btn-success mr-2" :disabled=disableAddButton()>Add <BIconPlusSquare /></button>
      </div>
    </template>
  </AModal>

  <!-- Remove Domain Admin Modal -->
  <AModal
    v-model="removeDomainAdminDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '30rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Delete Domain</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-xl text-danger">
        Are you sure you want to remove <strong>{{ adminToRemove.name }}</strong> as a Domain Admin User for this domain?
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2"><BIconXCircleFill /> Cancel</button>
        <button @click="acceptRemoveDomainAdminDialog" class="btn btn-danger mr-2"><BIconTrashFill /> Remove</button>
      </div>
    </template>
  </AModal>
</template>

<script setup>
import { ref } from 'vue'
import { useDomainAdminStore } from '../stores/domainAdminStore.js'
import AModal from '../components/AModal.vue'
import getNameForIamId, { notFoundMessage } from '@/lib/getNameForIamId'
import { IamIdFormatErrorStr, IamIdRegex } from '@/lib/constants'
import { useUserInfoStore } from '../stores/userInfoStore.js'
const userInfoStore = useUserInfoStore()
const domainAdminStore = useDomainAdminStore()

const addDomainAdminDialogOpen = ref(false)
const newDomainAdminId = ref('')
const newAdminName = ref('Enter Users IAM ID and press Search Button.')
const customName = ref('')
const canAddNewAdmin = ref(false)
const showNameSetFields = ref(false)
const uuidFormatErrorFound = ref(false)

const removeDomainAdminDialogOpen = ref(false)
const adminToRemove = ref({})
const existingDomainAdmins = ref([])

const props = defineProps({ // eslint-disable-line no-undef
  readOnly: Boolean,
  domainId: String
})

function openAddDomainAdminDialog () {
  addDomainAdminDialogOpen.value = true
  existingDomainAdmins.value = domainAdminStore.domainAdminsNotAssignedToDomain(props.domainId)
}

function openRemoveDomainAdminDialog (admin) {
  adminToRemove.value = admin
  removeDomainAdminDialogOpen.value = true
}

function closeDialogs () {
  addDomainAdminDialogOpen.value = false
  removeDomainAdminDialogOpen.value = false
  adminToRemove.value = {}
  uuidFormatErrorFound.value = false
  newAdminName.value = ''
  newDomainAdminId.value = ''
  customName.value = ''
  showNameSetFields.value = false
  canAddNewAdmin.value = false
}

function disableAddButton () {
  // if we are adding a new user, make sure their first and last name are set.
  if (showNameSetFields.value) {
    return !canAddNewAdmin.value || customName.value.length === 0
  } else {
    return !canAddNewAdmin.value
  }
}

async function acceptAddDomainAdminDialog () {
  // if we are adding a new user, add them to the database first with their user-specified name
  if (showNameSetFields.value) {
    await userInfoStore.createUser(newDomainAdminId.value, customName.value)
    showNameSetFields.value = false
  }
  await domainAdminStore.addDomainAdmin({
    iamId: newDomainAdminId.value,
    domainId: props.domainId
  })
  closeDialogs()
}

async function acceptRemoveDomainAdminDialog () {
  const admin = adminToRemove.value
  await domainAdminStore.removeDomainAdmin(admin.user_id, props.domainId)
  closeDialogs()
}

async function requestNameFromIamId () {
  if (IamIdRegex.test(newDomainAdminId.value)) {
    uuidFormatErrorFound.value = false
    const nameJson = await getNameForIamId(newDomainAdminId.value)
    // if it is a string, then it must have been an error
    if (typeof nameJson === 'string') {
      newAdminName.value = nameJson
      if (nameJson === notFoundMessage) {
        // if it was a not-found error, let them click 'Add' to create the person in the db
        canAddNewAdmin.value = true
        showNameSetFields.value = true
      } else {
        // if it's an unexpected error, keep the `Add` button disabled.
        canAddNewAdmin.value = false
      }
    } else {
      // if it wasn't an error, display the name that was found
      newAdminName.value = `${nameJson.name}`
      canAddNewAdmin.value = true
    }
  } else {
    uuidFormatErrorFound.value = true
    canAddNewAdmin.value = false
    newAdminName.value = ''
  }
}

// runs whenever you change the 'Existing User' domain admin dropdown
function setNameFromList () {
  // hide the "Temporary Name" field if it was visible before
  showNameSetFields.value = false
  customName.value = ''
  // clear any errors
  uuidFormatErrorFound.value = false
  if (newDomainAdminId.value !== '') {
    // if the select was changed to anything except the blank option, enable the add button
    canAddNewAdmin.value = true
    newAdminName.value = ' '
  } else {
    // if the select was changed to the blank option, disable the add button and reset the 'Name' box.
    canAddNewAdmin.value = false
    newAdminName.value = ''
  }
}

</script>

<style>
.one-button {
  width: 8rem;
}
</style>
