<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h2>Global Admins</h2>
    <button v-if="!readOnly" type="button" @click="addGlobalAdmin" class="btn btn-success ml-2">Add <BIconPlusSquare /></button>
  </div>
  <table class="table bg-light">
    <thead>
    <tr>
      <th>Name</th>
      <th v-if="!readOnly"></th> <!-- placeholder for remove button -->
    </tr>
    </thead>
    <tbody>
    <tr v-for="globalAdmin in globalAdminList" :key="globalAdmin.user_id">
      <td>{{ globalAdmin.name }}</td>
      <td v-if="globalAdminList.length > 1 && !readOnly" class="fit-content">
        <button
          type="button"
          @click="removeGlobalAdmin(globalAdmin)"
          class="btn btn-danger"
        ><BIconTrashFill />Remove</button>
      </td>
    </tr>
    </tbody>
  </table>

  <!-- Add Global Admin Modal -->
  <AModal
    v-model="addGlobalAdminDialogOpen"
    @click-outside="cancelAddGlobalAdminDialog"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <h3>Add Global Admin</h3>
    </template>
    <template #body>
      <div class="form-group">
        <label for="iam_id">IAM ID</label>
        <div class="input-group">
          <input
            v-model="newAdminId"
            type="text"
            id="iam_id"
            name="iam_id"
            placeholder="IAM ID of new Global Admin User"
            size="36"
            class="form-control">
          <button @click="requestNameFromIAMId" class="btn btn-primary">Search <BIconSearch /></button>
        </div>
        <p v-if="uuidFormatErrorFound" class="text-danger">
          Formatting Error: Check that the format of your IAM ID is valid.
          {{ IamIdFormatErrorStr }}
        </p>
      </div>
      <div class="form-group">
        <label for="newGlobalAdminName">Name</label>
        <textarea
          id="newGlobalAdminName"
          name="newGlobalAdminName"
          disabled
          placeholder="Enter Users IAM ID and press Search Button."
          v-model="newAdminName"
          class="form-control"
          ></textarea>
      </div>
      <div class="form-group" :hidden=!showNameSetFields>
        <label for="cust_name">Temporary Name of New Global Admin:</label>
        <div class="input-group">
          <input
            v-model="customName"
            type="text"
            id="cust_name"
            name="cust_name"
            placeholder="Name of Global Admin"
            class="form-control">
        </div>
        <p class="text-muted">
          This name will just be used until the specified user logs in to AMPS for the first time, at which point
          the name associated with their credentials will take its place.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="cancelAddGlobalAdminDialog" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddGlobalAdminDialog" class="btn btn-success mr-2" :disabled=disableAddButton()>Add <BIconPlusSquare /></button>
      </div>
    </template>
  </AModal>

  <AModal
    v-model="removeConfirmDialogOpen"
    @click-outside="removeConfirmDialogOpen = false"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <h3>Remove Global Admin User</h3>
    </template>
    <template #body>
      <p class="text-danger font-size-xlg">
        <BIconExclamationCircleFill /> Are you sure you want to remove this user?
      </p>
      <div class="font-size-xlg">{{removeAdmin.name}}</div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="cancelRemoveGlobalAdminDialog" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptRemoveGlobalAdminDialog" class="btn btn-danger mr-2">Remove <BIconTrashFill /></button>
      </div>
    </template>
  </AModal>
  <AToast />
</template>

<script setup>
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useGlobalAdminStore } from '../stores/globalAdminStore.js'
import { useAllRolesStore } from '../stores/allRolesStore.js'
import AModal from '../components/AModal.vue'
import AToast from '../components/AToast.vue'
import getNameForIamId, { notFoundMessage } from '@/lib/getNameForIamId'
import { IamIdFormatErrorStr, IamIdRegex } from '@/lib/constants'
import { useUserInfoStore } from '../stores/userInfoStore.js'
const userInfoStore = useUserInfoStore()
const globalAdminStore = useGlobalAdminStore()
const allRolesStore = useAllRolesStore()

const { globalAdminList } = storeToRefs(globalAdminStore)

defineProps({ // eslint-disable-line no-undef
  readOnly: Boolean
})

const route = useRoute()

const addGlobalAdminDialogOpen = ref(false)
const newAdminId = ref('')
const newAdminName = ref('')
const customName = ref('')
const removeConfirmDialogOpen = ref(false)
const canAddNewAdmin = ref(false)
const showNameSetFields = ref(false)
const removeAdmin = ref({})
const uuidFormatErrorFound = ref(false)

watch(route.path, async () => {
  await allRolesStore.getAllUserRoles()
}, { immediate: true })

function addGlobalAdmin () {
  addGlobalAdminDialogOpen.value = true
}

function cancelAddGlobalAdminDialog () {
  // hide the dialog
  addGlobalAdminDialogOpen.value = false
  // reset all of these dialog variables to their defaults
  newAdminId.value = ''
  newAdminName.value = ''
  customName.value = ''
  uuidFormatErrorFound.value = false
  showNameSetFields.value = false
  canAddNewAdmin.value = false
}

function disableAddButton () {
  // if we are adding a new user, make sure their first and last name are set.
  if (showNameSetFields.value) {
    return !canAddNewAdmin.value || customName.value.length === 0
  } else {
    return !canAddNewAdmin.value
  }
}

async function acceptAddGlobalAdminDialog () {
  // if we are adding a new user, add them to the database first with their user-specified name
  if (showNameSetFields.value) {
    await userInfoStore.createUser(newAdminId.value, customName.value)
    showNameSetFields.value = false
  }
  await globalAdminStore.addGlobalAdmin(newAdminId.value)
  addGlobalAdminDialogOpen.value = false
  newAdminId.value = ''
  newAdminName.value = ''
}

function removeGlobalAdmin (admin) {
  removeAdmin.value = admin
  removeConfirmDialogOpen.value = true
}

function cancelRemoveGlobalAdminDialog () {
  removeAdmin.value = {}
  removeConfirmDialogOpen.value = false
}

async function acceptRemoveGlobalAdminDialog () {
  await globalAdminStore.removeGlobalAdmin(removeAdmin.value.user_id)
  removeAdmin.value = {}
  removeConfirmDialogOpen.value = false
}

async function requestNameFromIAMId () {
  if (IamIdRegex.test(newAdminId.value)) {
    uuidFormatErrorFound.value = false
    const nameJson = await getNameForIamId(newAdminId.value)
    // if it is already of type string, then it must have been an error
    if (typeof nameJson === 'string') {
      newAdminName.value = nameJson
      if (nameJson === notFoundMessage) {
        // if it was a not-found error, let them click 'Add' to create the person in the db
        canAddNewAdmin.value = true
        showNameSetFields.value = true
      } else {
        // if it's an unexpected error, keep the `Add` button disabled.
        canAddNewAdmin.value = false
      }
    } else {
      // if it wasn't an error, display the name that was found
      newAdminName.value = `${nameJson.name}`
      canAddNewAdmin.value = true
    }
  } else {
    uuidFormatErrorFound.value = true
    canAddNewAdmin.value = false
    newAdminName.value = ''
  }
}
</script>

<style>
.font-size-xlg {
  font-size: 1.6rem;
}

.fit-content {
  width: 9rem;
  min-width: fit-content;
}
</style>
