import { defineStore } from 'pinia'
import apiCall from '../lib/api'
import { useAllRolesStore } from './allRolesStore.js'

export const useDomainAdminStore = defineStore('domainAdmin', {
  state: () => {
    return {
      domainAdminList: [],
      userDomains: []
    }
  },
  getters: {
    domainAdminsForDomain (state) {
      return (domainId) => this.domainAdminList.filter(admin => admin.domains.some(domain => domain.domain_id === domainId))
    },
    domainAdminsNotAssignedToDomain (state) {
      return (domainId) => this.domainAdminList.filter(admin => {
        const adminAssignedDomain = admin.domains.find(domain => domain.domain_id === domainId)
        return !adminAssignedDomain
      })
    }
  },
  actions: {
    async addDomainAdmin ({ iamId, domainId }) {
      const response = await apiCall(`/api/domainAdmin/${iamId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          domain_id: domainId
        }),
        showToast: true,
        loadingMessage: 'Adding Domain Admin...'
      })
      if (response.ok) {
        const rolesStore = useAllRolesStore()
        await rolesStore.getAllUserRoles()
      }
    },
    async removeDomainAdmin (userIdToRemove, domainIdToRemove) {
      const response = await apiCall(`/api/domainAdmin/${userIdToRemove}/domain/${domainIdToRemove}`, {
        method: 'DELETE',
        showToast: true,
        loadingMessage: 'Removing Domain Admin...'
      })
      if (response.ok) {
        const rolesStore = useAllRolesStore()
        await rolesStore.getAllUserRoles()
      }
    },
    async getDomainsForUser (userId) {
      const response = await apiCall(`api/domainAdmin/${userId}`, {
        showToast: false
      })
      if (response.ok) {
        const json = await response.json()
        this.userDomains = json.data.map((domain) => {
          return {
            domainName: domain.domain_name,
            domainId: domain.domain_id,
            domainDescription: domain.domain_description
          }
        })
      }
    }
  }
})
