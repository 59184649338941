/**
 * This regex should match exactly DomainNameSchema in the backend
 *
 * If updated, also update DomainNameSchema and DomainNameListSchema in the backend:
 * github.com/byu-oit/app-perm-mapper-svc/blob/dev/app/src/models/domainModels.ts
 * @type {RegExp}
 */
export const domainNameRegex = /^\w+\.\w+$/
export const domainNameFormatErrorStr = 'You can only include letters, numbers and underscores, with a single period in the middle.'

/**
 * This should NOT be the exact same regex as PermissionNameSchema in the backend.
 * The backend regex checks for the entire permission name which includes the name of the corresponding domain.
 * This regex should only check the portion of the permission name that follows the domain name.
 *
 * Regex breakdown:
 * - (?:\w+\.)*
 *   - the (?:) indicates a non-capturing group, since we just want a group but don't need to capture
 *   - the \w+\. inside the non-capturing group means "any number of word characters ([a-zA-Z0-9_]) followed by a period"
 *   - the * means there has to be 0 or more of the previous group
 *     This allows for word characters but only allows periods at the end of a word portion
 * - \w+ (the final permission name portion)
 *   - while the previous group will capture all the permission name text that consists of period-separated words,
 *     it will not capture the final portion (which shouldn't have a period at the end).
 *     This final portion verifies that the last piece of the permission name doesn't have a period at the end.
 *
 * If updated, also double-check PermissionNameSchema in the backend:
 * github.com/byu-oit/app-perm-mapper-svc/blob/dev/app/src/models/permissionModels.ts
 * @type {RegExp}
 */
export const permissionNameRegex = /^(?:\w+\.)*\w+$/
export const permissionNameFormatErrorStr = 'You can only include letters, numbers, and underscores, with periods in between.'

// todo stop using regex and format-error-help-strings and instead just pass along the error message that the backend sends

// BYU version of variables
const workerIdRegex = /^\d{9}$/ // byuId
const byuIdFormatErrorStr = 'A BYU ID should be 9 digits long'
// CES version of variables
export const UUIDRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
export const UUIDFormatErrorStr = 'UUIDs should contain only numbers, dashes, and A-F characters.'

export const IamIdRegex = process.env.VUE_APP_ORG === 'BYU' ? workerIdRegex : UUIDRegex
export const IamIdFormatErrorStr = process.env.VUE_APP_ORG === 'BYU' ? byuIdFormatErrorStr : UUIDFormatErrorStr
