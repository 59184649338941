import { defineStore } from 'pinia'
import apiCall from '../lib/api'
import { useApplicationsStore } from '../stores/applicationsStore'
export const usePermissionMappingStore = defineStore('permissionMapping', {
  state: () => {
    return {
      permissionMappingList: {},
      permissionsUserCanMap: []
    }
  },
  getters: {
    forAppId (state) {
      return (appId) => state.permissionMappingList[appId]
    },
    unmappedPermissions (state) {
      return (mappedPermissions) => {
        const mappedPermissionIds = mappedPermissions.map(permission => permission.id)
        return state.permissionsUserCanMap.filter(permission => !mappedPermissionIds.includes(permission.id))
      }
    }
  },
  actions: {
    async getPermissionsToMap () {
      console.log('getting permissions available to map (for a domain admin)')
      const response = await apiCall('/api/permissionMapping/available',
        {
          loadingMessage: 'Loading...'
        }
      )
      if (response.ok) {
        const json = await response.json()
        this.permissionsUserCanMap = json.data.map(permission => {
          return {
            id: permission.permission_id,
            name: permission.permission_name,
            description: permission.permission_description
          }
        })
      }
    },
    async addPermissionMapping ({ appId, permissionId, appName, clientId }) {
      if (!appId) { // no internal id - time to create one
        if (!clientId) {
          // should never get here but if we are at least we're logging it
          console.error('No client Id provided')
        }
        const applicationsStore = useApplicationsStore()
        await applicationsStore.createApplication({ clientId })
        const appDetails = applicationsStore.forClientId(clientId)
        appId = appDetails.id // set the appId to the one that was just created
        if (!appId) {
          // should never get here but if we are at least we're logging it
          console.error(`App Id unsuccessfully retrieved after creation for clientId ${clientId}`)
        }
      }
      console.log(`Mapping permission ${permissionId} to application ${appId}`)
      await apiCall(`/api/permissionMapping/${appId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            permission_id: permissionId,
            app_name: appName
          }),
          showToast: true,
          loadingMessage: 'Mapping Permission...'
        }
      )
    },
    async removePermissionMapping ({ appId, mappingId }) {
      console.log(`Removing permission mapping ${mappingId}`)
      await apiCall(`/api/permissionMapping/${appId}?mapping_id=${mappingId}`,
        {
          method: 'DELETE',
          showToast: true,
          loadingMessage: 'Removing Permission Mapping...'
        }
      )
    }
  }
})
