<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center align-self-start">
      <h5>Mapped Applications</h5>
      <button
        v-if="!readOnly"
        @click="openAddApplicationDialog"
        type="button"
        class="btn btn-sm btn-success ml-2"
      >
        <BIconPlusSquare /> Map
      </button>
    </div>
    <div class="d-flex align-self-center">
      <button v-show="nextIdentifier.valueOf() !== pageSize.valueOf()" @click="backFive" class="page-link"><BIconSkipBackwardFill/></button>
      <button v-show="nextIdentifier.valueOf() !== pageSize.valueOf()" @click="previousPage" class="page-link"><BIconPlayFill style="transform: scaleX(-1);"/></button>
      <span v-show="pageNumber.valueOf() > 1" @click="toBeginning" class="page-link" style="cursor: pointer;"> 1 </span>
      <span v-show="pagesAmount > 1" class="page-link border-primary" style="margin-right: 10px; margin-left: 10px; border-width: medium"> {{pageNumber.valueOf()}}</span>
      <span v-show="pageNumber.valueOf() < pagesAmount.valueOf()" @click="toEnd" class="page-link" style="cursor: pointer;">{{pagesAmount.valueOf()}}</span>
      <button v-show="nextIdentifier.valueOf() < applicationsCount.valueOf()" @click="nextPage" class="page-link"><BIconPlayFill/></button>
      <button v-show="nextIdentifier.valueOf() < applicationsCount.valueOf()" @click="forwardFive" class="page-link"><BIconSkipForwardFill/></button>
    </div>
  </div>
  <table class="table table-sm mt-2">
    <tbody>
      <tr v-if="!mappedApplicationsList || mappedApplicationsList.length === 0">
        <td>
          <span class="text-muted">Not mapped to any applications.</span>
        </td>
      </tr>
      <tr v-for="application of mappedApplicationsList" :key="application.id">
        <td>
          {{ application.clientId }} ({{ application.name }})
        </td>
        <td class="one-button">
          <button v-if="!readOnly" @click="openRemoveApplicationDialog(application)" type="button" class="btn btn-sm btn-danger">
            <BIconTrashFill /> Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- Add Application Modal -->
  <AModal
    v-model="addApplicationMappingDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '50rem' }"
  >
    <template #header>
      <h3>Map <em>{{permission.name}}</em> to an Application</h3>
    </template>
    <template #body>
      <div class="d-flex" style="margin-bottom: 5px">
        <input v-model="userInput" type="text" placeholder="Search by Client ID" style="width: 100%; margin-right: 5px">
        <button @click="filterApps" class="page-link">Search</button>
      </div>
      <ul v-if="applicationsAvailableToMap.length > 0" style="list-style-type: none">
        <li v-for="application in applicationsAvailableToMap" :key="application.id" class="page-link" style="cursor: pointer" @click="applicationToMap=application;">
          {{ application.clientId }} ({{ application.name }})
        </li>
      </ul>
      <div v-else>No applications available to map. {{clientIdWarning}}</div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <span v-if="applicationToMap" class="page-link mr-2" >{{applicationToMap.clientId}} ({{applicationToMap.name}})</span>
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2" >Cancel <BIconXCircleFill /></button>
        <button
          @click="acceptAddApplicationMappingDialog()"
          class="btn btn-success mr-2" :disabled="!applicationToMap">Map <BIconPlusSquare />
        </button>
      </div>
    </template>
  </AModal>

  <!-- Remove Permission Modal -->
  <AModal
    v-model="removeApplicationMappingDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '50rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Remove Mapped Permission</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-xl text-danger">
          Are you sure you want to remove <strong><em>{{permission.name}}</em></strong> from <strong>{{ applicationMappingToRemove.name }}</strong>?
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2"><BIconXCircleFill /> Cancel</button>
        <button
          @click="acceptRemoveApplicationsMappingDialog()"
          class="btn btn-danger mr-2"><BIconTrashFill /> Remove
        </button>
      </div>
    </template>
  </AModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useApplicationsStore } from '../stores/applicationsStore.js'
import { usePermissionMappingStore } from '../stores/permissionMappingStore'
import AModal from '../components/AModal.vue'
import { storeToRefs } from 'pinia'
import { BIconPlayFill, BIconSkipBackwardFill, BIconSkipForwardFill } from 'bootstrap-icons-vue'
const props = defineProps({ // eslint-disable-line no-undef
  permission: Object,
  readOnly: Boolean
})

const applicationsStore = useApplicationsStore()
const permissionMappingStore = usePermissionMappingStore()

const { applicationsList, pageSize, nextIdentifier, applicationsCount, pageNumber, pagesAmount } = storeToRefs(applicationsStore)
const mappedApplicationsList = ref([]) // list of applications that have the selected permission mapped to it
const applicationsAvailableToMap = ref([]) // the list of applications that do NOT have the selected permission mapped to it
const addApplicationMappingDialogOpen = ref(false)
const removeApplicationMappingDialogOpen = ref(false)
const applicationMappingToRemove = ref({})
const applicationToMap = ref(null) // must be null so that disable functionality works in modal
const userInput = ref('') // used to search by Client ID
const clientIdWarning = ref('')

watch(() => props.permission, async () => {
  await applicationsStore.getApplications({ pageSize: 10, nextIdentifier: 0, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value = 1
}, { immediate: true })

function closeDialogs () {
  addApplicationMappingDialogOpen.value = false
  removeApplicationMappingDialogOpen.value = false
  applicationMappingToRemove.value = {}
  applicationToMap.value = null // must be null so that disable functionality works in modal
  userInput.value = ''
  applicationsAvailableToMap.value = []
}

async function filterApps () {
  clientIdWarning.value = ''
  await applicationsStore.getApplications({ pageSize: 10, nextIdentifier: 0, clientId: userInput.value })
  if (applicationsList.value.length === 0) {
    clientIdWarning.value = 'No applications with a matching client ID.'
  }
  applicationsAvailableToMap.value = applicationsStore.applicationsNotMappedToPermissionId(props.permission.id)
}

function openAddApplicationDialog () {
  addApplicationMappingDialogOpen.value = true
}

function openRemoveApplicationDialog (permission) {
  applicationMappingToRemove.value = permission
  removeApplicationMappingDialogOpen.value = true
}

async function acceptAddApplicationMappingDialog () {
  await permissionMappingStore.addPermissionMapping({
    appId: applicationToMap.value.id,
    permissionId: props.permission.id,
    appName: applicationToMap.value.name,
    clientId: applicationToMap.value.clientId
  })
  await reloadMappedApplications()
  closeDialogs()
}

async function acceptRemoveApplicationsMappingDialog () {
  await permissionMappingStore.removePermissionMapping({
    appId: applicationMappingToRemove.value.id,
    mappingId: applicationMappingToRemove.value.mappingId
  })
  await reloadMappedApplications()
  closeDialogs()
}

async function reloadMappedApplications () {
  await applicationsStore.getApplications({ pageSize: 10, nextIdentifier: 0, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value = 1
}

async function nextPage () {
  await applicationsStore.getApplications({ pageSize: pageSize.value, nextIdentifier: nextIdentifier.value, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value++
}

async function previousPage () {
  const previousIdentifier = nextIdentifier.value - (pageSize.value * 2)
  await applicationsStore.getApplications({ pageSize: pageSize.value, nextIdentifier: previousIdentifier, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value--
}

async function forwardFive () {
  const diff = pageNumber.value + 5 <= pagesAmount.value ? 5 : pagesAmount.value - pageNumber.value
  const next = nextIdentifier.value + (pageSize.value * (diff - 1))
  await applicationsStore.getApplications({ pageSize: pageSize.value, nextIdentifier: next, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value += diff
}

async function backFive () {
  const diff = pageNumber.value > 5 ? 5 : pageNumber.value - 1
  const previous = nextIdentifier.value - (pageSize.value * (diff + 1))
  await applicationsStore.getApplications({ pageSize: pageSize.value, nextIdentifier: previous, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value -= diff
}

async function toBeginning () {
  await applicationsStore.getApplications({ pageSize: pageSize.value, nextIdentifier: 0, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value = 1
}

async function toEnd () {
  const lastIdentifier = pageSize.value * (pagesAmount.value - 1)
  await applicationsStore.getApplications({ pageSize: pageSize.value, nextIdentifier: lastIdentifier, permissionId: props.permission.id })
  mappedApplicationsList.value = applicationsStore.applicationsWithMappedPermissionId(props.permission.id)
  pageNumber.value = pagesAmount.value
}

</script>

<style>
</style>
