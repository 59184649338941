<template>
  <nav class="nav left-padding bottom-shadow">
    <template v-if="userInfoStore.role === 'globalAdmin'">
      <router-link to="/roles" class="nav-link" active-class="active">Roles</router-link>
      <router-link to="/domains" class="nav-link" active-class="active">Domains</router-link>
      <router-link to="/audit" class="nav-link" active-class="active">Audit</router-link>
      <router-link to="/applications" class="nav-link" active-class="active">Applications</router-link>
      <router-link to="/permissions" class="nav-link" active-class="active">Permissions</router-link>
    </template>
    <template v-else-if="userInfoStore.role === 'domainAdmin'">
      <router-link to="/domains" class="nav-link" active-class="active">Domains</router-link>
      <router-link to="/audit" class="nav-link" active-class="active">Audit</router-link>
      <router-link to="/applications" class="nav-link" active-class="active">Applications</router-link>
      <router-link to="/permissions" class="nav-link" active-class="active">Permissions</router-link>
    </template>
    <template v-else-if="userInfoStore.role === 'auditor'">
      <router-link to="/roles" class="nav-link" active-class="active">Roles</router-link>
      <router-link to="/domains" class="nav-link" active-class="active">Domains</router-link>
      <router-link to="/audit" class="nav-link" active-class="active">Audit</router-link>
      <router-link to="/applications" class="nav-link" active-class="active">Applications</router-link>
      <router-link to="/permissions" class="nav-link" active-class="active">Permissions</router-link>
    </template>
  </nav>
</template>

<script setup>
import { useUserInfoStore } from '../stores/userInfoStore.js'
const userInfoStore = useUserInfoStore()
</script>

<style>
.active {
  border-bottom: thick solid var(--primary, black);
}
.left-padding {
  padding-left: 14rem;
}
.bottom-shadow {
  position: relative;
  box-shadow: 0rem 0.2rem 0.25rem 0rem #ccc;
  z-index: 10;
}
</style>
