import { createApp } from 'vue'
import UserInfo from './components/UserInfo.vue'
import AppNavigation from './components/AppNavigation.vue'
import DevBanner from './components/DevBanner.vue'
import AppSettings from './components/AppSettings.vue'
import { authentication } from './authentication.js'
import ALoader from './components/ALoader.vue'

// Icons
import {
  BIconCaretDownFill,
  BIconCaretRightFill,
  BIconCheckCircleFill,
  BIconCloudArrowDown,
  BIconExclamationCircleFill,
  BIconInfoCircle,
  BIconPen,
  BIconPlusSquare,
  BIconRepeat,
  BIconSearch,
  BIconSortAlphaDown,
  BIconSortAlphaUp,
  BIconTrashFill,
  BIconXCircleFill,
  BIconXLg
} from 'bootstrap-icons-vue'

export default function ({
  pinia,
  router,
  app
}) {
  createApp(UserInfo).use(pinia).mount('#user-info')
  createApp(ALoader).use(pinia).mount('#loader')
  createApp(DevBanner).use(pinia).mount('#dev-banner')
  createApp(AppNavigation).use(router).mount('#app-navigation')
  createApp(AppSettings).use(pinia).use(router).mount('#app-settings')

  authentication((details) => console.log('signed in!', '\n', details), () => window.location.assign('/signin'))

  const date2Str = dt => `${dt.getUTCMonth() + 1}/${dt.getUTCDate()}/${dt.getUTCFullYear()}`

  app.config.globalProperties.$formatters = {
    date (value) {
      if (!value) return value
      const fmt = date2Str
      if (value.toUTCString) {
        return fmt(value)
      }
      return fmt(new Date(value))
    },
    dateTime (value) {
      if (!value) return value
      const fmt = dt => dt.toLocaleDateString() + ' | ' + dt.toLocaleTimeString()
      if (value.toLocaleDateString && value.toLocaleTimeString) {
        return fmt(value)
      }
      return fmt(new Date(value))
    }
  }
  app.component('BIconCaretDownFill', BIconCaretDownFill)
  app.component('BIconCaretRight', BIconCaretRightFill)
  app.component('BIconCheckCircleFill', BIconCheckCircleFill)
  app.component('BIconCloudArrowDown', BIconCloudArrowDown)
  app.component('BIconExclamationCircleFill', BIconExclamationCircleFill)
  app.component('BIconInfoCircle', BIconInfoCircle)
  app.component('BIconPen', BIconPen)
  app.component('BIconPlusSquare', BIconPlusSquare)
  app.component('BIconRepeat', BIconRepeat)
  app.component('BIconSearch', BIconSearch)
  app.component('BIconSortAlphaDown', BIconSortAlphaDown)
  app.component('BIconSortAlphaUp', BIconSortAlphaUp)
  app.component('BIconTrashFill', BIconTrashFill)
  app.component('BIconXCircleFill', BIconXCircleFill)
  app.component('BIconXLg', BIconXLg)
}
