import { defineStore } from 'pinia'
import { useAppSettingsStore } from './appSettingsStore.js'
import apiCall, { EXPIRED_REFRESH_TOKEN_MESSAGE } from '../lib/api'
import { useDomainStore } from './domainStore.js'

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    iamId: '',
    userId: '',
    name: '',
    // auth props
    isGlobalAdmin: false,
    isDomainAdmin: false,
    isAuditor: false,
    initPromise: null
  }),
  getters: {
    role: (state) => {
      const appSettingsStore = useAppSettingsStore()
      const overrideRole = appSettingsStore.role
      if (overrideRole) {
        return overrideRole
      }
      if (state.isGlobalAdmin) {
        return 'globalAdmin'
      } else if (state.isDomainAdmin) {
        return 'domainAdmin'
      } else if (state.isAuditor) {
        return 'auditor'
      }
      return ''
    }
  },
  actions: {
    async initializeUser (newValue) {
      console.table(newValue)
      if (!this.initPromise) {
        this.initPromise = (async () => {
          await Promise.all([this.getUserRole()])
        })()
      }
      return this.initPromise
    },
    async createUser (iamId, fullName) {
      const url = '/api/user'
      await apiCall(url, {
        method: 'PUT',
        showToast: true,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ iamId, fullName })
      })
    },
    async setProxyRole () {
      const { role } = useAppSettingsStore()
      this.isGlobalAdmin = role === 'globalAdmin'
      this.isDomainAdmin = role === 'domainAdmin'
      this.isAuditor = role === 'auditor'
      if (role === '') {
        // reset role from BE
        await this.getUserRole()
      }
      // some things with the store need to be refreshed when changing role, so do that here:
      if (this.isGlobalAdmin) {
        const { getDomains } = useDomainStore()
        await getDomains()
      }
    },
    async getUserRole () {
      const url = '/api/user/getRole'
      const response = await apiCall(url, { showToast: false })
      if (response.ok) {
        const { data } = await response.json()
        if (data && data.length === 1) {
          const userInfo = data[0]
          this.userId = userInfo.user_id
          this.iamId = userInfo.iam_id
          this.name = userInfo.name
          this.isGlobalAdmin = userInfo.is_global_admin
          this.isDomainAdmin = userInfo.is_domain_admin
          this.isAuditor = userInfo.is_auditor
          if (!this.isGlobalAdmin && !this.isDomainAdmin && !this.isAuditor) {
            // User has no role in the system - redirect to a page to instruct them about access
            window.location.replace('/noauth.html')
          }
        }
      } else { // ROLE NOT FOUND, MUST REDIRECT USER TO THE APPROPRIATE PAGE
        // todo add some handling so that testing locally doesn't require clearing cookies after every server restart
        if (response.status === 404) {
          // User has no role in the system - redirect to a page to instruct them about access
          window.location.replace('/noauth.html')
        } else if (response.status === 401 && response.message !== EXPIRED_REFRESH_TOKEN_MESSAGE) {
          console.warn('401 when attempting to get user information')

          // TODO Adjust the error handling here once we adjust the backend to work properly with Tyk & BYU

          // Not an expiration issue - BFF is having troubles authenticating with the backend which needs to be addressed by a developer
          window.location.replace('/systemError.html')
        } else {
          console.warn('unknown error when attempting to get user information')
          // unable to identify user for unknown reason
          window.location.replace('/systemError.html')
        }

        this.iamId = ''
        this.name = ''
        this.isGlobalAdmin = false
        this.isDomainAdmin = false
        this.isAuditor = false
      }
    }
  }
})
