<template>
  <table v-if="!loading" class="table bg-light">
    <thead>
      <tr class="white-background">
        <th>
          <div class="d-flex flex-direction-column justify-content-begin align-items-center">
            <h2>{{ currentApplication.clientId }} ({{ currentApplication.name }})</h2>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="white-background">
        <td colspan="2">
          <AppPermissionsList :readOnly="!isDomainAdmin" :appId="currentApplication.id" :clientId="currentApplication.clientId" :appName="currentApplication.name"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { useRoute } from 'vue-router'
import AppPermissionsList from '@/components/AppPermissionsList.vue'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import { useApplicationsStore } from '../stores/applicationsStore'
import { ref, watch } from 'vue'

const userInfoStore = useUserInfoStore()
const applicationsStore = useApplicationsStore()

const { currentApplication } = storeToRefs(applicationsStore)
const { isDomainAdmin } = storeToRefs(userInfoStore)

const route = useRoute()
const loading = ref(false)

watch(() => route.path, async () => {
  loading.value = true
  await applicationsStore.getOneApplication({ appId: route.params.id })
    .finally(() => { loading.value = false })
}, { immediate: true })
</script>
