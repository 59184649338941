<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h4>Permissions</h4>
    <button
      v-if="!readOnly"
      @click="openAddPermissionDialog"
      type="button"
      class="btn btn-sm btn-success ml-2"
    >
      <BIconPlusSquare /> Add
    </button>
  </div>
  <table class="table table-sm mt-2">
    <tbody>
    <tr v-if="!permissionList || permissionList.length === 0">
      <td colspan="3">
        <span class="text-muted">No permissions configured.</span>
      </td>
    </tr>
    <template v-for="permission of permissionList" :key="permission.id">
      <tr :class="{ 'white-background': permission.id === selectedPermission.id }">
        <td>
          <div>
            <span @click="goToPermissionDetails(permission)" class="text-xl hover-underline" style="cursor: pointer">{{ permission.name }}</span>
          </div>
          <div v-show="permission.id === selectedPermission.id"><em>{{permission.description}}</em></div>
        </td>
        <td class="one-button" v-if="!readOnly">
          <button @click="openRemovePermissionDialog(permission)" type="button" class="btn btn-sm btn-danger">
            <BIconTrashFill /> Remove
          </button>
        </td>
      </tr>
    </template>
    </tbody>
  </table>

  <!-- Add Permission Modal -->
  <AModal
    v-model="addPermissionDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <h3>Add Permission</h3>
    </template>
    <template #body>
      <div>
        Permission ID (computed): <strong>{{ domainStore.domainById(domainId).domainName }}.{{newPermissionName}}</strong>
      </div>
      <div class="form-group">
        <label for="permissionName">Permission</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{ domainStore.domainById(domainId).domainName }}</span>
          </div>
          <input
                  v-model="newPermissionName"
                  type="text"
                  id="permissionName"
                  name="permissionName"
                  placeholder="new permission"
                  size="36"
                  class="form-control"
                  aria-describedby="newPermissionHelpText">
          <p v-if="domainPermissionErrorFound" class="text-danger">
            Formatting Error: Check that the format of your domain permission name is valid.
            {{ permissionNameFormatErrorStr }}
          </p>
        </div>
        <small id="newPermissionHelpText" class="form-text text-muted">
          Permissions are usually described by the permitted context, operation, etc. within the domain. Examples: <code>all_access</code>, <code>ssn.readonly</code>, <code>admission_decision.make</code>
        </small>
      </div>
      <div class="form-group">
        <label for="newPermissionDescription">Description (optional)</label>
        <textarea
          v-model="newPermissionDescription"
          id="newPermissionDescription"
          name="newPermissionDescription"
          placeholder="Description (optional)"
          rows="3"
          class="form-control"
        ></textarea>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddPermissionDialog" class="btn btn-success mr-2" :disabled="!newPermissionName">Add <BIconPlusSquare /></button>
      </div>
    </template>
  </AModal>

  <!-- Remove Permission Modal -->
  <AModal
    v-model="removePermissionDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '30rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Remove Permission</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-xl text-danger">
        Are you sure you want to remove <strong>{{ permissionToRemove.name }}</strong>?
        <br>
        Warning: this will also remove this permission from all applications!
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2"><BIconXCircleFill /> Cancel</button>
        <button @click="acceptRemovePermissionDialog" class="btn btn-danger mr-2"><BIconTrashFill /> Remove</button>
      </div>
    </template>
  </AModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { usePermissionStore } from '../stores/permissionStore.js'
import { useDomainStore } from '../stores/domainStore.js'
import { permissionNameFormatErrorStr, permissionNameRegex } from '../lib/constants'
import AModal from '../components/AModal.vue'
import router from '../router/router'

const permissionStore = usePermissionStore()
const domainStore = useDomainStore()

const addPermissionDialogOpen = ref(false)
const newPermissionName = ref('')
const newPermissionDescription = ref('')

const removePermissionDialogOpen = ref(false)
const permissionToRemove = ref({})

const domainPermissionErrorFound = ref(false)

const props = defineProps({ // eslint-disable-line no-undef
  readOnly: Boolean,
  domainId: String
})

// Used to make sure we have the right domain when editing
const selectedPermission = ref({})
const permissionList = ref([])

watch(() => props.domainId, async (newDomainId, oldDomainId) => {
  await permissionStore.getAllPermissions()
  permissionList.value = permissionStore.forDomainId(newDomainId)
}, { immediate: true })

function openAddPermissionDialog () {
  addPermissionDialogOpen.value = true
}

function openRemovePermissionDialog (permission) {
  permissionToRemove.value = permission
  removePermissionDialogOpen.value = true
}

function closeDialogs () {
  addPermissionDialogOpen.value = false
  removePermissionDialogOpen.value = false
  domainPermissionErrorFound.value = false
  permissionToRemove.value = {}
  newPermissionName.value = ''
  newPermissionDescription.value = ''
}

async function acceptAddPermissionDialog () {
  if (!permissionNameRegex.test(newPermissionName.value)) {
    domainPermissionErrorFound.value = true
    return
  }

  const domain = domainStore.domainById(props.domainId)
  const permissionName = `${domain.domainName}.${newPermissionName.value}`
  await permissionStore.addPermission({
    domainId: props.domainId,
    name: permissionName,
    description: newPermissionDescription.value
  })
  permissionList.value = permissionStore.forDomainId(props.domainId)
  closeDialogs()
}

async function acceptRemovePermissionDialog () {
  const permission = permissionToRemove.value
  await permissionStore.removePermission({
    domainId: props.domainId,
    permissionId: permission.id
  })
  permissionList.value = permissionStore.forDomainId(props.domainId)
  closeDialogs()
}

function goToPermissionDetails (permission) {
  router.push({
    name: 'permissionDetails',
    params: { permissionId: permission.id, domainId: permission.domain_id }
  })
}

</script>

<style>
.one-button {
  width: 8rem;
}
.two-buttons {
  width: 14rem;
  min-width: fit-content;
}
.white-background {
  background-color: white;
}
.expander {
  transition: transform .3s ease;
}
/* Remove the top border on an expanded row item */
.hide-top-border {
  border-top: none !important;
}
/* Remove the highlight on rows that contain tables */
.hidden-table:hover {
  background-color: white !important;
}
.hover-underline:hover {
  text-decoration: underline;
}
</style>
