<template>
  <table class="table table-sm bg-white">
    <thead>
      <tr>
        <th
          v-for="(column, columnIndex) in props.columns"
          :key="columnIndex"
        >
        <div class="action d-flex justify-content-around align-items-center" @click="sortBy(columnIndex)">
          <span>
            {{ column.name }}
            <BIconSortAlphaUp v-if="columnIndex === sortIndex && sortDir === ZTOA" class="text-primary" />
            <BIconSortAlphaDown v-else-if="columnIndex === sortIndex" class="text-primary" />
            <BIconSortAlphaDown v-else class="text-muted" />
          </span>
        </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, rowIndex) in sortedRows" :key="rowIndex">
        <td v-for="(column, columnIndex) in props.columns" :key="columnIndex">{{ row[column.key] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed, ref } from 'vue'

const ATOZ = -1
const ZTOA = 1

// eslint-disable-next-line no-undef
const props = defineProps({
  columns: Array,
  rows: Array
})

const sortIndex = ref(-1)
const sortDir = ref(ATOZ)

const sortedRows = computed(() => {
  if (sortIndex.value >= 0) {
    const sortKey = props.columns[sortIndex.value].key
    return props.rows.slice(0).sort((a, b) => {
      const fieldA = a[sortKey]
      const fieldB = b[sortKey]
      if (fieldA < fieldB) {
        return sortDir.value
      } else {
        return -1 * sortDir.value
      }
    })
  } else {
    return props.rows
  }
})

function sortBy (index) {
  if (sortIndex.value === index) {
    if (sortDir.value === ATOZ) {
      sortDir.value = ZTOA
    } else {
      sortDir.value = ATOZ
    }
  } else {
    sortIndex.value = index
  }
}
</script>
