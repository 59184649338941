import { defineStore } from 'pinia'
export const useAppSettingsStore = defineStore('appSettings', {
  state: () => {
    return {
      debugApiCalls: false,
      chosenRole: ''
    }
  },
  getters: {
    role (state) {
      return state.chosenRole
    }
  }
})
