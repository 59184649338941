<template>
  <select v-model="reportName" class="form-control">
    <option value="" selected disabled hidden>Select A Report</option>
    <option v-for="meta in reportDataForRole" v-bind:key="meta.name" :value="meta.name">{{ meta.description }}</option>
  </select>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import { useUserInfoStore } from '../stores/userInfoStore.js'
import { useAuditStore } from '../stores/auditStore.js'
import { ref, watch } from 'vue'
import { useAppSettingsStore } from '@/stores/appSettingsStore'
import { route } from 'express/lib/router'

const { role } = useUserInfoStore()
const auditStore = useAuditStore()
const { reportName, reportMetadata } = storeToRefs(auditStore)
const appSettingsStore = useAppSettingsStore()
const { chosenRole } = storeToRefs(appSettingsStore)
const roleNames = {
  globalAdmin: 'global_admin',
  domainAdmin: 'domain_admin',
  auditor: 'auditor'
}
const reportDataForRole = ref([])

watch(() => route.path, async () => {
  await build()
}, { immediate: true })

watch(chosenRole, async () => {
  await auditStore.getReportMetadata()
  if (chosenRole.value === '') {
    reportDataForRole.value = reportMetadata.value.filter(meta => meta.accessor_roles.includes(roleNames[role]))
  } else {
    reportDataForRole.value = reportMetadata.value.filter(meta => meta.accessor_roles.includes(roleNames[chosenRole.value]))
  }
})

async function build () {
  await auditStore.getReportMetadata()
  reportDataForRole.value = reportMetadata.value.filter(meta => meta.accessor_roles.includes(roleNames[role]))
}

</script>
