import { defineStore } from 'pinia'
import apiCall from '../lib/api'

export const usePermissionStore = defineStore('permission', {
  state: () => {
    return {
      permissionList: [],
      currentPermission: null
    }
  },
  getters: {
    permissionListIsEmpty: (state) => {
      return () => state.permissionList.length === 0
    },
    forDomainId (state) {
      return (domainId) => state.permissionList.filter((permission) => permission.domain_id === domainId)
    }
  },
  actions: {
    async getPermissionsForDomain (domainId) {
      console.log('getting permissionList for ', domainId)
      const response = await apiCall(`/api/permission/list/${domainId}`,
        {
          loadingMessage: 'Loading...'
        }
      )
      if (response.ok) {
        const json = await response.json()
        // go through each permission and update it in the same location it currently exists
        for (const permission of json.data) {
          const index = this.permissionList.findIndex(item => item.id === permission.permission_id)
          this.permissionList[index] = {
            id: permission.permission_id,
            name: permission.permission_name,
            description: permission.permission_description,
            domain_id: permission.domain_id
          }
        }
      }
    },
    async getAllPermissions () {
      console.log('getting all permissions')
      const response = await apiCall('/api/permission/list',
        {
          loadingMessage: 'Loading...'
        }
      )
      if (response.ok) {
        const json = await response.json()
        this.permissionList = json.data.map((permission) => {
          return {
            id: permission.permission_id,
            name: permission.permission_name,
            description: permission.permission_description,
            domain_id: permission.domain_id
          }
        })
      }
    },
    async getPermission ({ domainId, permissionId }) {
      console.log(`Getting permission: ${permissionId}`)
      const response = await apiCall(`/api/domains/${domainId}/permissions/${permissionId}`,
        {
          loadingMessage: 'Getting Permission...'
        }
      )
      if (response.ok) {
        const json = await response.json()
        this.permissionList = json.data.map((permission) => {
          return {
            id: permission.permission_id,
            name: permission.permission_name,
            description: permission.permission_description,
            domain_id: permission.domain_id
          }
        })
        this.currentPermission = this.permissionList[0]
      }
    },
    async addPermission ({ domainId, name, description }) {
      console.log(`Adding permission ${name} to domain ${domainId}`)
      await apiCall(`/api/permission/${domainId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            permission_name: name,
            permission_description: description
          }),
          showToast: true,
          loadingMessage: 'Adding Permission...'
        }
      )
      await this.getAllPermissions()
    },
    async removePermission ({ domainId, permissionId }) {
      await apiCall(`/api/permission/${domainId}?permission_id=${permissionId}`,
        {
          method: 'DELETE',
          showToast: true,
          loadingMessage: 'Removing Permission...'
        }
      )
      await this.getAllPermissions()
    },
    async updatePermissionDescription ({ domainId, permissionId, description }) {
      await apiCall(`/api/domains/${domainId}/permission/${permissionId}`, {
        method: 'PUT',
        body: JSON.stringify({
          permission_description: description
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        showToast: true,
        loadingMessage: 'Updating...'
      })
      await this.getPermissionsForDomain(domainId)
    }
  }
})
