import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router/router.js'
import commonComponents from './commonComponents.js'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const pinia = createPinia()

const app = createApp(App)
app.use(pinia).use(router).mount('#app')
commonComponents({ pinia, router, app })
app.component('VueDatePicker', VueDatePicker)
