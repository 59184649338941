<template>
  <h1>Audit Reports</h1>
  <div class="form-row">
    <div class="form-group col-xl-3">
      <label for="report-name">Report to view</label>
      <ReportSelector id="report-name" />
    </div>
    <div class="form-group col-md-6 col-xl-3">
      <label for="start-date">Start Date</label>
      <VueDatePicker
        v-model="startDate"
        id="start-date"
        close-on-scroll
        auto-apply
        no-today
        prevent-min-max-navigation
        input-class-name="date-form-control-mimic"
        :enable-time-picker="false"
        :max-date="endDate || new Date()"
      />
    </div>
    <div class="form-group col-md-6 col-xl-3">
      <label for="end-date">End Date</label>
      <VueDatePicker
        v-model="endDate"
        id="end-date"
        close-on-scroll
        auto-apply
        no-today
        prevent-min-max-navigation
        input-class-name="date-form-control-mimic"
        :enable-time-picker="false"
        :min-date="startDate || null"
        :max-date="new Date()"
      />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-xl-3" v-show="displayDomainSelector()">
      <label for="report-name">Domain</label>
      <DomainSelector id="domain-name" />
    </div>
    <div class="form-group col-xl-9" v-show="auditStore.reportName === 'permissions'">
      <label for="report-name">Application: {{reportAppDescription || 'All'}}</label>
      <ApplicationSelector id="application-name" />
    </div>
  </div>

  <template v-if="auditStore.currentReport">
    <div class="d-flex flex-direction-column justify-content-begin align-items-center">
      <h2>{{ auditStore.currentReport.description }}</h2>
      <a :href="auditStore.downloadUrl" download class="btn btn-primary ml-2 text-white">Download as CSV <BIconCloudArrowDown /></a>
    </div>
    <ReportView :rows="reportData" :columns="auditStore.currentReport.columns" />
  </template>
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useAuditStore } from '../stores/auditStore.js'
import ReportSelector from '../components/ReportSelector.vue'
import ReportView from '../components/ReportView.vue'
import DomainSelector from '../components/DomainSelector.vue'
import ApplicationSelector from '../components/ApplicationSelector.vue'
import { useDomainAdminStore } from '@/stores/domainAdminStore'
import { useDomainStore } from '@/stores/domainStore'
import { useUserInfoStore } from '@/stores/userInfoStore'
import { useAppSettingsStore } from '@/stores/appSettingsStore'

const auditStore = useAuditStore()
const domainAdminStore = useDomainAdminStore()
const userStore = useUserInfoStore()
const domainStore = useDomainStore()
const appSettingsStore = useAppSettingsStore()

const { chosenRole } = storeToRefs(appSettingsStore)
const { reportData, reportName, startDate, endDate, reportDomain, reportApplication, reportAppDescription } = storeToRefs(auditStore)
const { userDomains } = storeToRefs(domainAdminStore)
const { userId, isDomainAdmin } = storeToRefs(userStore)

const route = useRoute()

// Fetch report metadata on page load
watch(() => route.path, async () => {
  await resetPage()
}, { immediate: true })

// Fetch report data when a report is selected
watch(reportName, async () => {
  if (reportName.value && isAllowedToLoadReport()) {
    await auditStore.getReport()
  }
})

// Fetch report data when the date range changes
watch(startDate, async () => {
  if (reportName.value && isAllowedToLoadReport()) {
    await auditStore.getReport()
  }
})
watch(endDate, async () => {
  if (reportName.value && isAllowedToLoadReport()) {
    await auditStore.getReport()
  }
})

watch(reportDomain, async () => {
  await auditStore.getReport()
})

watch(reportApplication, async () => {
  await auditStore.getReport()
})

watch(chosenRole, async () => {
  await resetPage()
})

async function resetPage () {
  await userStore.getUserRole()
  // if the user is a domain admin, load only the list of domains that they have access to
  // if the user is a global admin or auditor, load the full list of domains
  if (isDomainAdmin.value) {
    await domainAdminStore.getDomainsForUser(userId.value)
  } else {
    await domainStore.getDomains()
  }
  await auditStore.getReportMetadata()
  reportData.value = []
  reportName.value = ''
  reportDomain.value = ''
  reportApplication.value = ''
  reportAppDescription.value = ''
}

function isAllowedToLoadReport () {
  // If you are a domain admin, only load if you have a domain name.
  // This keeps the 'domains' reports from running immediately and fetching data for ALL domains.
  // (domain admins should only have access to see history for the domains they have access to)
  if (isDomainAdmin.value) {
    if (reportDomain.value) return true
    else return false
  } else {
    return true
  }
}

function displayDomainSelector () {
  if (auditStore.reportName !== 'domains') return false
  if (isDomainAdmin.value) {
    return userDomains.value.length > 0
  } else {
    return true
  }
}
</script>

<style>
/* Passing in this class to the 'input-class-name' prop makes */
/*  the date selectors look like the report selector */
.date-form-control-mimic {
  background-clip: padding-box;
  border: 1px solid #00000029 !important;
  border-radius: 0 !important;
}
</style>
