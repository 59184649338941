import { defineStore } from 'pinia'
import apiCall from '../lib/api'
import { useAllRolesStore } from './allRolesStore.js'

export const useGlobalAdminStore = defineStore('globalAdmin', {
  state: () => ({
    globalAdminList: [],
    searchedUser: ''
  }),
  actions: {
    async addGlobalAdmin (id) {
      if (!id) {
        return false
      }
      const url = `/api/globalAdmin/${id}`
      const response = await apiCall(url, {
        method: 'POST',
        showToast: true,
        loadingMessage: 'Adding Global Admin...'
      })
      if (response.ok) {
        const allRolesStore = useAllRolesStore()
        await allRolesStore.getAllUserRoles()
      }
    },
    async removeGlobalAdmin (idToRemove) {
      const url = `/api/globalAdmin/${idToRemove}`
      const response = await apiCall(url, {
        method: 'DELETE',
        showToast: true,
        loadingMessage: 'Deleting Global Admin...'
      })
      if (response.ok) {
        const allRolesStore = useAllRolesStore()
        await allRolesStore.getAllUserRoles()
      }
    },
    async lookupUser (iamIdToLookup) {
      const url = `/api/user/lookup/${iamIdToLookup}`
      const response = await apiCall(url, { showToast: false })
      if (response.ok) {
        const { data } = await response.json()
        if (data && data.length === 1) {
          const userInfo = data[0]
          this.searchedUser = `${userInfo.name}}`
        } else {
          console.warn('More than 1 user returned in lookup')
        }
      } else {
        this.searchedUser = 'Not Found'
      }
    }
  }
})
