import { defineStore } from 'pinia'

export const useLoaderStore = defineStore('loader', {
  state: () => {
    return {
      loading: false,
      message: ''
    }
  },
  actions: {
    showLoading (message = 'Loading') {
      this.loading = true
      this.message = message
    },
    hideLoading () {
      this.loading = false
    }
  }
})
