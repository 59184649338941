<template>
  <template v-if="showSettings">
    <span v-nativeslot:header>App Settings</span>
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" v-model="debugApiCalls" id="appSettings--debugApiCalls">
      <label class="custom-control-label" for="appSettings--debugApiCalls">Debug API Calls</label>
    </div>
    <div>
      <select class="form-control" v-model="chosenRole" @change="updateProxyUser()">
        <option value="globalAdmin">Global Admin</option>
        <option value="domainAdmin">Domain Admin</option>
        <option value="auditor">Auditor</option>
        <option value="">Reset role from DB</option>
      </select>
    </div>
  </template>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useRouter, useRoute } from 'vue-router'
import { useAppSettingsStore } from '../stores/appSettingsStore.js'
import { useUserInfoStore } from '../stores/userInfoStore.js'

const appSettingsStore = useAppSettingsStore()
const { debugApiCalls, chosenRole } = storeToRefs(appSettingsStore)
const { setProxyRole } = useUserInfoStore()

const router = useRouter()
const route = useRoute()
const showSettings = process.env.VUE_APP_STAGE_LEVEL === 'dev'

const vNativeslot = {
  mounted: (el, binding) => {
    el.setAttribute('slot', binding.arg)
  },
  updated: (el, binding) => el.setAttribute('slot', binding.arg)
}

async function updateProxyUser () {
  await setProxyRole()
  // redirect domainAdmin out of /roles
  if (chosenRole.value === 'domainAdmin' && route.path === '/roles') {
    await router.replace({ path: '/' })
  }
}

</script>

<style>
</style>
