<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h2>Permissions</h2>
    <button v-show="isDomainAdmin" type="button" @click="addPermission" class="btn btn-sm btn-success ml-2"><BIconPlusSquare /> Add</button>
    <div class="flex-grow-1"></div>
    <div class="display-grid">
        <label for="selectedDomainId">Filter By:</label>
        <select v-model="selectedDomainId" id="selectedDomainId" name="selectedDomainId" class="form-control">
          <option value="all">All Domains</option>
          <option v-for="domain in domainList" :value="domain.domainId" :key="domain.domainId">{{domain.domainName}}</option>
        </select>
    </div>
  </div>
  <table class="table bg-light">
    <thead>
    <tr>
      <th>Name</th>
      <th colspan="2">Description</th>
      <th></th> <!-- Placeholder for add/remove buttons -->
    </tr>
    </thead>
    <tbody>
      <template v-for="permission in filteredPermissionList" :key="permission.id">
        <!--   Permissions displayed based on the computed domain in filteredPermissionList     -->
        <tr :class="{ 'white-background': permission.id === expandedPermission.id }">
          <td>
            <span @click="goToDetails(permission)" class="text-xl hover-underline" style="cursor: pointer">{{ permission.name }}</span>
          </td>
          <td>
            <span>{{ permission.description }}</span>
          </td>
          <td class="two-buttons">
            <button v-show="isDomainAdmin"  @click="editPermission(permission)" type="button" class="btn btn-sm btn-primary"><BIconPen /> Edit</button>
            <button v-show="isDomainAdmin" @click="removePermission(permission)" type="button" class="btn btn-sm btn-danger ml-2"><BIconTrashFill /> Remove</button>
          </td>
          <td></td>
        </tr>
      </template>
    </tbody>
  </table>
  <!-- New Permission -->
  <AModal
    v-model="addPermissionModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '50rem' }"
  >
    <template #header>
      <div class="d-flex flex-direction-column justify-content-begin align-items-center">
        <h3>Add Permission</h3>
      </div>
    </template>
    <template #body>
      <div>
        Permission ID (computed): <strong>{{newPermissionDomain.domainName}}.{{newPermissionName}}</strong>
      </div>
      <div class="form-group">
        <label for="newPermissionDomainId">Permission</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <select v-model="newPermissionDomain" id="newPermissionDomain" name="newPermissionDomain" class="form-control input-group-text">
              <option value="" disabled selected>Select Domain</option>
              <option v-for="domain in domainList" :value="domain" :key="domain.domainId">{{domain.domainName}}</option>
              <option :value=otherDomainSelectValue @click=morePermissionsAlert>Other</option>
            </select>
          </div>
          <input
            v-model="newPermissionName"
            name="newPermissionName"
            id="newPermissionName"
            type="text"
            placeholder="Permission Name (domain_name.resource_verb)"
            size="30"
            class="form-control"/>
          <p v-if="permissionNameFormattingErrorFound" class="text-danger">
            Formatting Error: Check that a domain has been selected and the format of your permission name is valid.
            {{ permissionNameFormatErrorStr }}
          </p>
        </div>
        <small id="newPermissionHelpText" class="form-text text-muted">
          Permissions should be named using the domain name followed by a resource verb, like: <code>ces.admissions.resource_verb</code>
        </small>
        <label for="newPermissionDescription">Permission Description</label>
        <input
          v-model="newPermissionDescription"
          name="newPermissionDescription"
          id="newPermissionDescription"
          type="text"
          placeholder="Permission Description"
          size="100"
          class="form-control"/>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddModal" class="btn btn-success mr-2" :disabled="!newPermissionName">Add <BIconPlusSquare /></button>
      </div>
    </template>
  </AModal>
  <!-- Edit Permission Modal -->
  <AModal
    v-model="updatePermissionModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '50rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Edit Permission</h3>
    </template>
    <template #body>
      <div class="form-group">
        <label for="newPermissionName">Permission Name</label>
        <input
          v-model="selectedPermission.name"
          name="selectedPermissionName"
          id="selectedPermissionName"
          type="text"
          size="30"
          class="form-control"
          disabled/>
        <label for="editPermissionDescription">Permission Description</label>
        <input
          v-model="newPermissionDescription"
          name="newPermissionDescription"
          id="newPermissionDescription"
          type="text"
          placeholder="Enter Permission Description Here"
          size="100"
          class="form-control"/>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptEditModal" class="btn btn-success mr-2">Update <BIconCheckCircleFill /></button>
      </div>
    </template>
  </AModal>
  <!-- Delete Permissions Modal -->
  <AModal
    v-model="deletePermissionModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '50rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Delete Permission</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-xl text-danger">Are you sure you want to remove the permission <strong>{{ selectedPermission.name }}</strong>?
        <br>
        <br>
        All associated mappings will also be removed!
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeModal" class="btn btn-outline-secondary mr-2"><BIconXCircleFill /> Cancel</button>
        <button @click="acceptDeleteModal" class="btn btn-danger mr-2"><BIconTrashFill /> Remove</button>
      </div>
    </template>
  </AModal>
  <AToast />
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import { usePermissionStore } from '../stores/permissionStore.js'
import { useDomainStore } from '../stores/domainStore'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import { useGlobalAdminStore } from '../stores/globalAdminStore'
import { permissionNameFormatErrorStr, permissionNameRegex } from '../lib/constants'
import { createGlobalAdminHelpPopup } from '../lib/util'

import AModal from '../components/AModal.vue'
import AToast from '../components/AToast.vue'
import router from '../router/router'

const globalAdminStore = useGlobalAdminStore()
// The logged-in user's role is userInfoStore.role
const userInfoStore = useUserInfoStore()
const { isDomainAdmin } = storeToRefs(userInfoStore)

const permissionStore = usePermissionStore()
const { permissionList } = storeToRefs(permissionStore)

const filteredPermissionList = computed(() => {
  if (selectedDomainId.value === 'all') {
    return permissionList.value
  } else {
    return permissionStore.forDomainId(selectedDomainId.value)
  }
})

const domainStore = useDomainStore()
const { domainList } = storeToRefs(domainStore)

// Interact with router
const route = useRoute()

// Used to make sure we have the right permission when editing
const selectedPermission = ref({})

// Used to expand permissions
const expandedPermission = ref({})

// Used to filter the list of permissions by domain
const selectedDomainId = ref('all')

// Determines if the update dialog should show
const updatePermissionModalOpen = ref(false)

// Determines if the delete dialog should show
const deletePermissionModalOpen = ref(false)

// Determines if the add modal should show
const addPermissionModalOpen = ref(false)

// Input string for the text field inside the dialogs
const newPermissionDomain = ref('')
const newPermissionName = ref('')
const newPermissionDescription = ref('')

const permissionNameFormattingErrorFound = ref(false)

// Fetch data on page load
watch(() => route.path, async () => {
  if (domainStore.domainListIsEmpty()) {
    await domainStore.getDomains()
  }
  await permissionStore.getAllPermissions()
}, { immediate: true })

const otherDomainSelectValue = 'other'

function morePermissionsAlert () {
  const globalAdminNames = globalAdminStore.globalAdminList.map(user => user.name)
  createGlobalAdminHelpPopup(globalAdminNames)
  // reset the dialog so that they can't actually select 'Other'
  newPermissionDomain.value = ''
}

/***
 * Opens the add permission modal
 */
function addPermission () {
  addPermissionModalOpen.value = true
}

/***
 * Opens the edit permission modal
 */
function editPermission (permission) {
  selectedPermission.value = permission
  newPermissionDescription.value = permission.description
  updatePermissionModalOpen.value = true
}

/***
 * Opens the remove permission modal
 */
function removePermission (permission) {
  selectedPermission.value = permission
  deletePermissionModalOpen.value = true
}

/***
 * Closes the modal windows
 */
function closeModal () {
  selectedPermission.value = {}
  addPermissionModalOpen.value = false
  updatePermissionModalOpen.value = false
  deletePermissionModalOpen.value = false
  permissionNameFormattingErrorFound.value = false
  newPermissionName.value = ''
  newPermissionDescription.value = ''
  newPermissionDomain.value = ''
}

async function acceptAddModal () {
  const selectedDomain = newPermissionDomain.value.domainName
  const enteredPermission = newPermissionName.value
  const newName = selectedDomain + '.' + enteredPermission
  if (!selectedDomain || selectedDomain === otherDomainSelectValue || !permissionNameRegex.test(enteredPermission)) {
    permissionNameFormattingErrorFound.value = true
    return
  }

  const newPermission = {
    domainId: newPermissionDomain.value.domainId,
    name: newName,
    description: newPermissionDescription.value
  }

  await permissionStore.addPermission(newPermission)

  closeModal()
}

async function acceptEditModal () {
  await permissionStore.updatePermissionDescription({ domainId: selectedPermission.value.domain_id, permissionId: selectedPermission.value.id, description: newPermissionDescription.value })
  closeModal()
}

async function acceptDeleteModal () {
  const toDelete = {
    domainId: selectedPermission.value.domain_id,
    permissionId: selectedPermission.value.id
  }
  await permissionStore.removePermission(toDelete)
  closeModal()
}

function goToDetails (permission) {
  router.push({
    name: 'permissionDetails',
    params: { permissionId: permission.id, domainId: permission.domain_id }
  })
}
</script>

<style scoped>
.text-xl {
  font-size: 1.3rem;
  font-weight: 600;
}

.display-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.expander {
  transition: transform .3s ease;
}

.two-buttons {
  width: 14rem;
  min-width: fit-content;
}

.white-background {
  background-color: white;
}

/* Remove the top border on an expanded row item */
.hide-top-border {
  border-top: none !important;
}

.hover-underline:hover {
  text-decoration: underline;
}
</style>
