<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center align-self-start">
      <h4>Mapped Permissions</h4>
      <button
        v-if="!readOnly"
        @click="openAddPermissionDialog"
        type="button"
        class="btn btn-sm btn-success ml-2"
      >
        <BIconPlusSquare /> Map
      </button>
    </div>
    <div class="d-flex flex-direction-column justify-content-begin align-items-center" style="justify-content: center; padding-bottom: 10px">
      <button v-show="currentIdentifier !== pageSize.valueOf()" @click="backFive" class="page-link"><BIconSkipBackwardFill/></button>
      <button v-show="currentIdentifier !== pageSize.valueOf()" @click="previousPage" class="page-link"><BIconPlayFill style="transform: scaleX(-1);"/></button>
      <span v-show="currentPageNumber > 1" @click="toBeginning" class="page-link" style="cursor: pointer;"> 1 </span>
      <span v-show="currentPagesAmount > 1" class="page-link border-primary" style="margin-right: 10px; margin-left: 10px; border-width: medium"> {{currentPageNumber.valueOf()}}</span>
      <span v-show="currentPageNumber < currentPagesAmount" @click="toEnd" class="page-link" style="cursor: pointer;">{{currentPagesAmount}}</span>
      <button v-show="currentIdentifier < permissionCount" @click="nextPage" class="page-link"><BIconPlayFill/></button>
      <button v-show="currentIdentifier < permissionCount" @click="forwardFive" class="page-link"><BIconSkipForwardFill/></button>
    </div>
  </div>
  <table class="table table-sm mt-2">
    <tbody>
      <tr v-if="!permDisplayList || permDisplayList.length === 0">
        <td colspan="3">
          <span class="text-muted">No permissions configured.</span>
        </td>
      </tr>
      <tr v-for="permission of permDisplayList" :key="permission.mappingId">
        <td>
          {{ permission.name }}
        </td>
        <td class="one-button">
          <button v-if="!readOnly" @click="openRemovePermissionDialog(permission)" type="button" class="btn btn-sm btn-danger">
            <BIconTrashFill /> Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- Add Permission Modal -->
  <AModal
    v-model="addPermissionMappingDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <h3>Map a Permission</h3>
    </template>
    <template #body>
      <select class="form-control" v-model="permissionToMap">
        <option v-for="permission in permissionsAvailableToMap" :value="permission" :key="permission.id">
          {{ permission.name }}
        </option>
      </select>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddPermissionMappingDialog" class="btn btn-success mr-2" :disabled="!permissionToMap.id">Map <BIconPlusSquare /></button>
      </div>
    </template>
  </AModal>

  <!-- Remove Permission Modal -->
  <AModal
    v-model="removePermissionMappingDialogOpen"
    @click-outside="closeDialogs"
    :style="{ 'max-width': '30rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Remove Mapped Permission</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-xl text-danger">
          Are you sure you want to remove <strong>{{ permissionMappingToRemove.name }}</strong>?
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="closeDialogs" class="btn btn-outline-secondary mr-2"><BIconXCircleFill /> Cancel</button>
        <button @click="acceptRemovePermissionMappingDialog" class="btn btn-danger mr-2"><BIconTrashFill /> Remove</button>
      </div>
    </template>
  </AModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import AModal from '../components/AModal.vue'
import { usePermissionMappingStore } from '../stores/permissionMappingStore.js'
import { useApplicationsStore } from '../stores/applicationsStore.js'
import { BIconPlayFill, BIconSkipBackwardFill, BIconSkipForwardFill } from 'bootstrap-icons-vue'
import { storeToRefs } from 'pinia'
// import { useRoute } from 'vue-router'

const permissionMappingStore = usePermissionMappingStore()
const applicationsStore = useApplicationsStore()

const addPermissionMappingDialogOpen = ref(false)

const removePermissionMappingDialogOpen = ref(false)
const permissionMappingToRemove = ref({})
const permissionToMap = ref({})

const props = defineProps({ // eslint-disable-line no-undef
  readOnly: Boolean,
  appId: String, // warning: can be null
  clientId: String,
  appName: String
})

// const route = useRoute()
const { pageSize, currentIdentifier, currentPagesAmount, currentPageNumber, currentPermissions } = storeToRefs(applicationsStore)
const permissionCount = ref([])
const mappedPermissionsList = ref([])
const permissionsAvailableToMap = ref([])
const permDisplayList = ref([])

watch(() => props, async () => {
  currentPageNumber.value = 1
  await permissionMappingStore.getPermissionsToMap()
  permissionCount.value = applicationsStore.currentPermissions.length
  currentIdentifier.value = pageSize.value
  permDisplayList.value = currentPermissions.value.slice(currentIdentifier.value - pageSize.value, currentIdentifier.value)
  mappedPermissionsList.value = applicationsStore.mappedPermissionsForAppId(props.appId) // refresh the list of currently mapped permissions
  permissionsAvailableToMap.value = permissionMappingStore.unmappedPermissions(mappedPermissionsList.value) // refresh the list of permissions that the user is able to add to this application
}, { immediate: true })

function updateList () {
  currentIdentifier.value = (currentPageNumber.value) * pageSize.value
  permDisplayList.value = currentPermissions.value.slice(currentIdentifier.value - pageSize.value, currentIdentifier.value)
}

function openAddPermissionDialog () {
  addPermissionMappingDialogOpen.value = true
}

function openRemovePermissionDialog (permission) {
  permissionMappingToRemove.value = permission
  removePermissionMappingDialogOpen.value = true
}

function closeDialogs () {
  addPermissionMappingDialogOpen.value = false
  removePermissionMappingDialogOpen.value = false
  permissionMappingToRemove.value = {}
  permissionToMap.value = {}
}

async function acceptAddPermissionMappingDialog () {
  const permissionId = permissionToMap.value.id
  let newAppId = props.appId
  if (permissionId) {
    newAppId = await permissionMappingStore.addPermissionMapping({
      appId: props.appId,
      clientId: props.clientId,
      permissionId: permissionToMap.value.id,
      appName: props.appName
    })
  } // else we don't want to try to save an empty permission
  await applicationsStore.getOneApplication({ appId: props.appId }) // reload the list of applications since a permission was added
  mappedPermissionsList.value = applicationsStore.mappedPermissionsForAppId(newAppId) // refresh the list of currently mapped permissions
  permissionsAvailableToMap.value = permissionMappingStore.unmappedPermissions(mappedPermissionsList.value) // refresh the list of permissions that the user is able to add to this application
  updateList()
  closeDialogs()
}

async function acceptRemovePermissionMappingDialog () {
  if (!props.appId) { // shouldn't get here (since an app with a mapped permission has already been created in our system) but if we do at least we're logging it
    console.warn(`Expected app id for client id ${props.clientId} but none was found`)
  }
  await permissionMappingStore.removePermissionMapping({
    appId: props.appId,
    mappingId: permissionMappingToRemove.value.mappingId
  })
  await applicationsStore.getOneApplication({ appId: props.appId }) // reload the list of applications since a permission was added
  mappedPermissionsList.value = applicationsStore.mappedPermissionsForAppId(props.appId) // refresh the list of currently mapped permissions
  permissionsAvailableToMap.value = permissionMappingStore.unmappedPermissions(mappedPermissionsList.value) // refresh the list of permissions that the user is able to add to this application
  updateList()
  closeDialogs()
}

async function nextPage () {
  currentPageNumber.value++
  updateList()
}

async function previousPage () {
  currentPageNumber.value--
  updateList()
}

async function forwardFive () {
  if (currentPageNumber.value < currentPagesAmount.value - 5) {
    currentPageNumber.value += 5
  } else {
    currentPageNumber.value = currentPagesAmount.value
  }
  updateList()
}

async function backFive () {
  if (currentPageNumber.value > 5) {
    currentPageNumber.value -= 5
  } else {
    currentPageNumber.value = 1
  }
  updateList()
}

async function toBeginning () {
  currentPageNumber.value = 1
  updateList()
}

async function toEnd () {
  currentPageNumber.value = currentPagesAmount.value
  updateList()
}

</script>

<style>
.one-button {
  width: 8rem;
  min-width: fit-content;
}
</style>
