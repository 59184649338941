import { useAppSettingsStore } from '../stores/appSettingsStore.js'
import { useToastStore } from '../stores/toastStore.js'
import { useLoaderStore } from '../stores/loaderStore.js'

export const EXPIRED_REFRESH_TOKEN_MESSAGE = 'expired_refresh_token' // should match the exported const

// This string must match the error thrown by the @byu-oit-sdk/express package when the authenticate() middleware fails
// (see the authenticate() function in node_modules/@byu-oit-sdk/express/cjs/AuthorizationCodeFlow.js)
export const NOT_LOGGED_IN_MESSAGE = 'The user is not signed in'

const MIN_LOADING_TIME = 500

export default async function (path, options = {}) {
  // if the stores are initialized outside this function,
  // they break when a store that includes api.js is called outside a component
  const appSettings = useAppSettingsStore()
  const toastStore = useToastStore()
  const loaderStore = useLoaderStore()

  const defaultOptions = {
    credentials: 'same-origin'
  }

  if (appSettings.debugApiCalls) {
    if (!options.method || options.method === 'GET') {
      return new Promise((resolve, reject) => {
        const response = window.prompt('Service response:', '{}')
        let json
        try {
          json = JSON.parse(response)
        } catch (err) {
          console.error(err)
        }
        if (json) {
          resolve({
            ok: true,
            async json () { return json },
            status: 200,
            statusText: 'Ok'
          })
        } else {
          resolve({
            ok: false,
            async json () { return {} },
            status: 404,
            statusText: 'Not Found'
          })
        }
      })
    }
  }
  let startTime
  if (options.loadingMessage) {
    loaderStore.showLoading(options.loadingMessage)
    startTime = Date.now()
  }

  // if the user has selected to act as a different role, tell the BFF about that role via a query parameter
  const url = new URL(path, window.location.origin)
  if (appSettings.role !== '') {
    url.searchParams.append('proxyRole', appSettings.role)
  }

  const response = await window.fetch(url, { ...defaultOptions, ...options })
  if (startTime) {
    const loadingTime = Date.now() - startTime
    if (loadingTime >= MIN_LOADING_TIME) loaderStore.hideLoading()
    else setTimeout(() => loaderStore.hideLoading(), MIN_LOADING_TIME - loadingTime)
  }

  if (!response.ok && response.status === 401) {
    const copy = response.clone()
    // check to see if the user is logged in
    try {
      const text = await copy.text()
      if (text === NOT_LOGGED_IN_MESSAGE) {
        window.location.replace('/signin')
        return response
      }
    } catch (err) {
      console.error('Unknown error while parsing response text.\n', err)
    }
  }
  if (options.showToast && !response.ok) {
    const copy = response.clone()
    try {
      const json = await copy.json()
      const message = json?.metadata?.validation_response?.message ?? url.toString()
      toastStore.addToast({
        title: `${response.status} ${response.statusText}`,
        time: '',
        body: message,
        style: response.ok ? 'success' : 'error',
        duration: response.ok ? 3000 : 25000
      })
    } catch (err) {
      console.error('Unknown error while parsing response body.\n', err)
      toastStore.addToast({
        title: `${response.status} ${response.statusText}`,
        time: '',
        body: url.toString(),
        style: response.ok ? 'success' : 'error',
        duration: response.ok ? 3000 : 25000
      })
    }
  }
  return response
}
