<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h2 style="margin-right: auto">Applications</h2>
    <button v-show="isDomainAdmin" type="button" @click="addApplication" class="btn btn-sm btn-success ml-2"><BIconPlusSquare /> Add</button>
    <div class="flex-grow-1"></div>
    <div class="form-inline">
      <input
        v-model="filterClientId"
        name="filterClientId"
        id="filterClientId"
        type="text"
        placeholder="Filter by Client ID"
        size="15"
        class="form-control"
        @keyup.enter="search"/>
      <button @click="search" class="btn btn-light"><BIconSearch /></button>
    </div>
  </div>
  <table class="table bg-light">
    <thead>
      <tr>
        <th>Name</th>
        <th>Client ID</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
    <template v-for="application in applicationsList" :key="application.clientId">
      <tr :class="{ 'white-background': application.clientId === selectedApplication.clientId }">
        <td>
          <span @click="goToDetails(application.id)" class="text-xl hover-underline" style="cursor: pointer">{{ application.name }}</span>
        </td>
        <td>
          <span>{{ application.clientId }}</span>
        </td>
        <td></td>
      </tr>
    </template>
    </tbody>
  </table>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center" style="justify-content: center">
    <button v-show="nextIdentifier !== pageSize.valueOf()" @click="backFive" :disabled="nextIdentifier.valueOf() === pageSize.valueOf()" class="page-link"><BIconSkipBackwardFill/></button>
    <button v-show="nextIdentifier !== pageSize.valueOf()" @click="previousPage" :disabled="nextIdentifier.valueOf() === pageSize.valueOf()" class="page-link"><BIconPlayFill style="transform: scaleX(-1);"/></button>
    <span v-show="pageNumber.valueOf() > 1" @click="toBeginning" class="page-link" style="cursor: pointer;"> 1 </span>
    <span class="page-link border-primary" style="margin-right: 10px; margin-left: 10px; border-width: medium"> {{pageNumber.valueOf()}}</span>
    <span v-show="pageNumber.valueOf() < pagesAmount.valueOf()" @click="toEnd" class="page-link" style="cursor: pointer;">{{pagesAmount.valueOf()}}</span>
    <button v-show="nextIdentifier < applicationsCount" @click="nextPage" :disabled="nextIdentifier.valueOf() >= applicationsCount.valueOf()" class="page-link"><BIconPlayFill/></button>
    <button v-show="nextIdentifier < applicationsCount" @click="forwardFive" :disabled="nextIdentifier.valueOf() >= applicationsCount.valueOf()" class="page-link"><BIconSkipForwardFill/></button>
  </div>
  <!-- Add Application to Database -->
  <AModal
    v-model="addApplicationModalOpen"
    @click-outside="closeModal"
    :style="{ 'max-width': '50rem' }"
    >
      <template #header>
        <div class="d-flex flex-direction-column justify-content-begin align-items-center">
          <h3>Add Application</h3>
        </div>
      </template>
      <template #body>
        <div class="form-group">
          <label>Client ID</label>
          <input
            v-model="newApplicationClientId"
            name="newApplicationClientId"
            id="newApplicationClientId"
            type="text"
            placeholder="Application Client ID"
            size="30"
            class="form-control"/>
          <p v-if="applicationClientIdFormattingErrorFound" class="text-danger">
            Formatting Error: Check that a domain has been selected and the format of your permission name is valid.
            {{ applicationClientIdFormatErrorStr }}
          </p>
        </div>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end w-100">
          <button @click="closeModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
          <button @click="acceptAddModal" class="btn btn-success mr-2" :disabled="!newApplicationClientId">Add <BIconPlusSquare /></button>
        </div>
      </template>
  </AModal>
  <AToast />
</template>

<script setup>
// Grab the list of applications from the applications store
import { useApplicationsStore } from '../stores/applicationsStore.js'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import AToast from '../components/AToast.vue'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import { BIconPlayFill, BIconSkipForwardFill, BIconSkipBackwardFill, BIconSearch } from 'bootstrap-icons-vue'
import router from '../router/router'
import AModal from '@/components/AModal.vue'
import { UUIDFormatErrorStr, UUIDRegex } from '@/lib/constants'

const applicationsStore = useApplicationsStore()
const { applicationsList, pageSize, nextIdentifier, applicationsCount, pageNumber, pagesAmount } = storeToRefs(applicationsStore)

// The logged-in user's role is userInfoStore.role
const userInfoStore = useUserInfoStore()
const { isDomainAdmin } = storeToRefs(userInfoStore)

// Interact with router
const route = useRoute()

const selectedApplication = ref({})
const addApplicationModalOpen = ref(false)
const newApplicationClientId = ref('')
const filterClientId = ref('')
const applicationClientIdFormatErrorStr = ref('')
const applicationClientIdFormattingErrorFound = ref(false)

watch(() => route.path, async () => {
  await applicationsStore.getApplications({ pageSize: 10, nextIdentifier: 0 })
  pageNumber.value = 1
}, { immediate: true })

function addApplication () {
  addApplicationModalOpen.value = true
}

async function search () {
  await applicationsStore.getApplications({
    pageSize: 10,
    nextIdentifier: 0,
    filterClientId: filterClientId.value
  })
  pageNumber.value = 1
}

async function nextPage () {
  await applicationsStore.getApplications({
    pageSize: pageSize.value,
    nextIdentifier: nextIdentifier.value,
    filterClientId: filterClientId.value
  })
  pageNumber.value++
}

async function previousPage () {
  const previousIdentifier = nextIdentifier.value - (pageSize.value * 2)
  await applicationsStore.getApplications({
    pageSize: pageSize.value,
    nextIdentifier: previousIdentifier,
    filterClientId: filterClientId.value
  })
  pageNumber.value--
}

async function forwardFive () {
  const diff = pageNumber.value + 5 <= pagesAmount.value ? 5 : pagesAmount.value - pageNumber.value
  const next = nextIdentifier.value + (pageSize.value * (diff - 1))
  await applicationsStore.getApplications({
    pageSize: pageSize.value,
    nextIdentifier: next,
    filterClientId: filterClientId.value
  })
  pageNumber.value += diff
}

async function backFive () {
  const diff = pageNumber.value > 5 ? 5 : pageNumber.value - 1
  const previous = nextIdentifier.value - (pageSize.value * (diff + 1))
  await applicationsStore.getApplications({
    pageSize: pageSize.value,
    nextIdentifier: previous,
    filterClientId: filterClientId.value
  })
  pageNumber.value -= diff
}

async function toBeginning () {
  await applicationsStore.getApplications({
    pageSize: pageSize.value,
    nextIdentifier: 0,
    filterClientId: filterClientId.value
  })
  pageNumber.value = 1
}

async function toEnd () {
  const lastIdentifier = pageSize.value * (pagesAmount.value - 1)
  await applicationsStore.getApplications({
    pageSize: pageSize.value,
    nextIdentifier: lastIdentifier,
    filterClientId: filterClientId.value
  })
  pageNumber.value = pagesAmount.value
}

function goToDetails (id) {
  router.push({ name: 'applicationDetails', params: { id: id } })
}

function closeModal () {
  addApplicationModalOpen.value = false
  applicationClientIdFormattingErrorFound.value = false
  newApplicationClientId.value = ''
}

async function acceptAddModal () {
  if (process.env.VUE_APP_ORG === 'BYU' && !UUIDRegex.test(newApplicationClientId.value)) {
    applicationClientIdFormattingErrorFound.value = true
    applicationClientIdFormatErrorStr.value = UUIDFormatErrorStr
    return
  }
  await applicationsStore.createApplication({ clientId: newApplicationClientId.value })
  await applicationsStore.getApplications({ pageSize: 10, nextIdentifier: 0 })
  pageNumber.value = 1
  closeModal()
}

</script>

<style scoped>
.text-xl {
  font-size: 1.3rem;
  font-weight: 600;
}
.expander {
  transition: transform .3s ease;
}
/* Remove the top border on an expanded row item */
.hide-top-border {
  border-top: none !important;
}
.white-background {
  background-color: white;
}

.hover-underline:hover {
  text-decoration: underline;
}
</style>
