import { defineStore } from 'pinia'

export const useToastStore = defineStore('toasts', {
  state: () => {
    return {
      toasts: []
    }
  },
  actions: {
    addToast (options) {
      const defaultOptions = {
        title: '',
        time: '',
        body: '',
        duration: 3000
      }
      const newToast = Object.assign({}, defaultOptions, options)
      newToast.id = this.toasts.length
      this.toasts.push(newToast)

      if (newToast.duration >= 0) {
        setTimeout(() => {
          this.removeToast(newToast)
        }, newToast.duration)
      }
      return newToast
    },
    removeToast (item) {
      const index = this.toasts.indexOf(item)
      if (index !== -1) {
        this.toasts.splice(index, 1)
      }
    },
    success (options) {
      this.addToast(Object.assign({}, options, { style: 'success' }))
    },
    error (options) {
      this.addToast(Object.assign({}, options, { style: 'error', duration: 10000 }))
    }
  }
})
