<template>
  <div class="role-display">{{ roleLabel }}</div>
  <router-view/>
</template>

<script setup>
import { computed } from 'vue'
import { useUserInfoStore } from './stores/userInfoStore.js'
const userInfoStore = useUserInfoStore()

const roleLabel = computed(() => {
  const role = userInfoStore.role
  if (role === 'globalAdmin') {
    return 'Global Admin'
  } else if (role === 'domainAdmin') {
    return 'Domain Admin'
  } else if (role === 'auditor') {
    return 'Auditor'
  }
  return ''
})
</script>

<style>
.role-display {
  font-weight: 600;
  font-size: 1.1rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 25000;
}
</style>
