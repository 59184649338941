import { defineStore } from 'pinia'
import apiCall from '../lib/api.js'

export const useApplicationsStore = defineStore('applications', {
  state: () => {
    return {
      applicationsList: [],
      mappedApplicationsList: [],
      applicationsCount: 0,
      pageSize: 10,
      nextIdentifier: 0,
      pageNumber: 1,
      pagesAmount: 0,
      /*
       * The values below all relate to getting and using a single application, and paginating through that application's
       * permissions
      */
      currentApplication: null,
      currentPermissions: null,
      currentPageNumber: 1,
      currentPagesAmount: 0,
      currentIdentifier: 0,
      permissionCount: 0
    }
  },
  getters: {
    forAppId (state) {
      return (appId) => state.applicationsList.find(application => application.id === appId)
    },
    forClientId (state) {
      return (clientId) => state.applicationsList.find(application => application.clientId === clientId)
    },
    mappedPermissionsForAppId (state) {
      return (appId) => {
        const application = state.applicationsList.find(application => application.id === appId)
        if (application) {
          return application.mappedPermissions
        }

        return []
      }
    },
    appListIsEmpty (state) {
      return () => {
        return state.applicationsList.length === 0
      }
    },
    applicationsWithMappedPermissionId (state) {
      return (permissionId) => {
        // only return a list of client id, application id, name, & mappingId that contain the given permission id in their mapped permission list
        const result = state.applicationsList.filter(application => application.mappedPermissions.find(mappedPermission => mappedPermission.id === permissionId))
        return result.map(application => {
          const permission = application.mappedPermissions.find(mappedPermission => mappedPermission.id === permissionId)
          return {
            clientId: application.clientId,
            id: application.id,
            name: application.name,
            mappingId: permission.mappingId
          }
        })
      }
    },
    applicationsNotMappedToPermissionId (state) {
      return (permissionId) => {
        // only return the list of applications that do NOT contain the given permission id in their mapped permission list
        return state.applicationsList.filter(application => {
          const result = application.mappedPermissions.find(mappedPermission => mappedPermission.id === permissionId)
          return result == null // only return applications that do NOT have this permission
        })
      }
    }
  },
  actions: {
    async getOneApplication ({ appId }) {
      const response = await apiCall(`/api/applications/${appId}`, {
        loadingMessage: 'Getting Application...',
        showToast: false
      })
      if (response.ok) {
        const data = await response.json()
        const tempList = data.data.map((application) => {
          const permissionList = application.permissions || []
          return {
            id: appId,
            clientId: application.client_id,
            name: application.app_description,
            mappedPermissions: permissionList.map(permission => {
              return { // name mapped permissions what the FE expects
                name: permission.domain_permission_name,
                id: permission.domain_permission_id,
                mappingId: permission.mapping_id
              }
            })
          }
        })
        this.currentApplication = tempList[0]
        this.currentPagesAmount = Math.ceil(this.currentApplication.mappedPermissions.length / this.pageSize)
        this.permissionCount = this.currentApplication.mappedPermissions.length
        this.currentPermissions = this.currentApplication.mappedPermissions
      }
    },
    async getApplications ({ pageSize, nextIdentifier, clientId, appName, permissionId, filterClientId }) {
      const q = new URLSearchParams({ page_size: pageSize, next_identifier: nextIdentifier })
      if (clientId !== undefined) {
        q.append('client_id', clientId)
      }
      if (appName !== undefined) {
        q.append('description', appName)
      }
      if (permissionId !== undefined) {
        q.append('permission_id', permissionId)
      }
      if (filterClientId !== undefined) {
        q.append('filter_client_id', filterClientId)
      }
      const response = await apiCall(`/api/applications?${q.toString()}`, {
        method: 'GET',
        showToast: true,
        loadingMessage: 'Loading Applications...'
      })
      if (response.ok) {
        const data = await response.json()
        this.applicationsList = data.data.map((application) => {
          const permissionList = application.permissions || []
          return {
            name: application.app_description,
            id: application.app_id,
            clientId: application.client_id,
            mappedPermissions: permissionList.map(permission => {
              return { // name mapped permissions what the FE expects
                name: permission.domain_permission_name,
                id: permission.domain_permission_id,
                mappingId: permission.mapping_id
              }
            })
          }
        })
        this.mappedApplicationsList = this.applicationsList.filter(app => app.id !== null)
        this.applicationsCount = data.info.collection_size
        this.nextIdentifier = data.info.next_identifier
        this.pagesAmount = Math.ceil(this.applicationsCount / this.pageSize)
      }
    },
    async createApplication ({ clientId }) {
      console.log('Creating Application for Client ID: ', clientId)
      await apiCall('/api/applications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client_id: clientId
        }),
        showToast: true,
        loadingMessage: 'Loading Applications...'
      })
    }
  }
})
