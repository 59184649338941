<template>
  <table class="table bg-light">
    <thead>
    <tr class="white-background">
      <th>
        <div class="d-flex flex-direction-column justify-content-begin align-items-center">
          <h2>{{currentPermission.name}}</h2>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
      <tr class="white-background">
        <td colspan="2">
          <PermissionsAppMappingsList :permission="currentPermission" :readOnly="!isDomainAdmin"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { useRoute } from 'vue-router'
import PermissionsAppMappingsList from '../components/PermissionsAppMappingsList.vue'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import { usePermissionStore } from '../stores/permissionStore'
import { watch } from 'vue'

const userInfoStore = useUserInfoStore()
const permissionStore = usePermissionStore()

const { currentPermission } = storeToRefs(permissionStore)
const { isDomainAdmin } = storeToRefs(userInfoStore)

const route = useRoute()

watch(() => route.path, async () => {
  await permissionStore.getPermission({ permissionId: route.params.permissionId, domainId: route.params.domainId })
}, { immediate: true })

</script>
