import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import DomainsPage from '../pages/DomainsPage.vue'
import PermissionsPage from '../pages/PermissionsPage.vue'
import ApplicationsPage from '../pages/ApplicationsPage.vue'
import AuditPage from '../pages/AuditPage.vue'
import RolesPage from '../pages/RolesPage.vue'
import ApplicationDetailsPage from '../pages/ApplicationDetailsPage.vue'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import { initializationPromise } from '../authentication.js'
import PermissionDetailsPage from '../pages/PermissionDetailsPage.vue'

const DEFAULT_PAGE_FOR_ROLE = {
  domainAdmin: '/domains',
  auditor: '/audit',
  globalAdmin: '/roles'
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/roles',
    name: 'roles',
    component: RolesPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'auditor']
    }
  },
  {
    path: '/domains',
    name: 'domains',
    component: DomainsPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'domainAdmin', 'auditor']
    }
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: PermissionsPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'domainAdmin', 'auditor']
    }
  },
  {
    path: '/domains/:domainId/permissions/:permissionId',
    name: 'permissionDetails',
    component: PermissionDetailsPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'domainAdmin', 'auditor']
    }
  },
  {
    path: '/applications',
    name: 'applications',
    component: ApplicationsPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'domainAdmin', 'auditor']
    }
  },
  {
    path: '/audit',
    name: 'audit',
    component: AuditPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'domainAdmin', 'auditor']
    }
  },
  {
    path: '/applications/:id',
    name: 'applicationDetails',
    component: ApplicationDetailsPage,
    meta: {
      authorizedRoles: ['globalAdmin', 'domainAdmin', 'auditor']
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async function (to, from, next) {
  const authorizedRoles = to.meta?.authorizedRoles ?? []
  await initializationPromise
  const store = useUserInfoStore()
  const role = store.role
  const defaultPath = DEFAULT_PAGE_FOR_ROLE[role] ?? '/'
  if (to.path === '/') {
    // redirect to default path for role
    if (defaultPath === '/') {
      // if the user has no role, redirect to the noauth page
      window.location.replace('/noauth.html')
    }
    return next({ path: defaultPath, replace: true })
  } else if (to.path !== '/' && authorizedRoles) {
    // enforce role requirements
    if (authorizedRoles.includes(role)) {
      return next()
    } else {
      return next({ path: defaultPath, replace: true })
    }
  }
  next()
})

export default router
