<template>
  <div class="d-flex flex-direction-column justify-content-begin align-items-center">
    <h2>Auditors</h2>
    <button v-if="!readOnly" @click="addAuditor" type="button" class="btn btn-success ml-2">Add <BIconPlusSquare /></button>
  </div>
  <table class="table bg-light">
    <thead>
    <tr>
      <th>Name</th>
      <th v-if="!readOnly"></th> <!-- Placeholder for remove button -->
    </tr>
    </thead>
    <tbody>
    <tr v-for="auditor in auditorList" :key="auditor.user_id">
      <td>{{`${auditor.name}`}}</td>
      <td v-if="auditorList.length > 1 && !readOnly" class="fit-content">
        <button @click="removeAuditor(auditor)" type="button" class="btn btn-danger"><BIconTrashFill /> Remove </button>
      </td>
    </tr>
    </tbody>
  </table>

  <!-- New Auditor Modal -->
  <AModal
    v-model="auditorModalOpen"
    @click-outside="cancelModal"
    :style="{ 'max-width': '30rem' }"
  >
    <template #header>
      <h3>Add Auditor</h3>
    </template>
    <template #body>
      <div class="form-group">
          <label for="iam_id">IAM ID</label>
        <div class="input-group">
          <input
            v-model="newAuditorId"
            type="text"
            id="iam_id"
            name="iam_id"
            placeholder="IAM ID of new Auditor User"
            size="36"
            class="form-control">
          <button @click="requestNameFromIamId" class="btn btn-primary">Search <BIconSearch /></button>
        </div>
        <p v-if="formatErrorFound" class="text-danger">
          Formatting Error: Check that the format of your IAM ID is valid.
          {{ IamIdFormatErrorStr }}
        </p>
      </div>
      <div class="form-group">
        <label for="newAuditorName">Name</label>
        <textarea
          id="newAuditorName"
          name="newAuditorName"
          disabled
          v-model="newAuditorName"
          placeholder="Enter Users IAM ID and press Search Button."
          class="form-control"
          ></textarea>
      </div>
      <div class="form-group" :hidden=!showNameSetFields>
        <label for="cust_name">Temporary Name of New Auditor:</label>
        <div class="input-group">
          <input
              v-model="customName"
              type="text"
              id="cust_name"
              name="cust_name"
              placeholder="Name of Auditor"
              class="form-control">
        </div>
        <p class="text-muted">
          This name will just be used until the specified user logs in to AMPS for the first time, at which point
          the name associated with their credentials will take its place.
        </p>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="cancelModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptAddAuditor" class="btn btn-success mr-2" :disabled=disableAddButton()>Add <BIconPersonPlusFill /></button>
      </div>
    </template>
  </AModal>
  <!-- Delete Auditor Modal -->
  <AModal
    v-model="deleteModalOpen"
    @click-outside="cancelModal"
    :style="{ 'max-width': '30rem', 'max-height': '70rem' }">
    <template #header>
      <h3>Delete Auditor</h3>
    </template>
    <template #body>
      <div class="removeDomainBody">
        <p class="text-danger font-size-xlg">
          <BIconExclamationCircleFill /> Are you sure you want to remove this user?
        </p>
        <div class="font-size-xlg">{{selectedAuditor.name}}</div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end w-100">
        <button @click="cancelModal" class="btn btn-outline-secondary mr-2">Cancel <BIconXCircleFill /></button>
        <button @click="acceptDeleteAuditor" class="btn btn-danger mr-2">Remove <BIconTrashFill /></button>
      </div>
    </template>
  </AModal>
  <AToast />
</template>

<script setup>
import { ref } from 'vue'
import { useAuditorStore } from '../stores/auditorStore.js'
import { storeToRefs } from 'pinia'
import AModal from '../components/AModal.vue'
import AToast from '../components/AToast.vue'
import getNameForIamId, { notFoundMessage } from '@/lib/getNameForIamId'
import { IamIdFormatErrorStr, IamIdRegex } from '@/lib/constants'
import { useUserInfoStore } from '../stores/userInfoStore.js'
const userInfoStore = useUserInfoStore()

const auditorModalOpen = ref(false)
const deleteModalOpen = ref(false)

const newAuditorId = ref('')
const newAuditorName = ref('')
const customName = ref('')
const selectedAuditor = ref({})
const canAddNewAuditor = ref(false)
const showNameSetFields = ref(false)
const formatErrorFound = ref(false)

const auditorStore = useAuditorStore()
const { auditorList } = storeToRefs(auditorStore)

defineProps({ // eslint-disable-line no-undef
  readOnly: Boolean
})

function addAuditor () {
  console.log('Added clicked')
  auditorModalOpen.value = true
}

function removeAuditor (auditor) {
  selectedAuditor.value = auditor
  deleteModalOpen.value = true
}

async function acceptAddAuditor () {
  // if we are adding a new user, add them to the database first with their user-specified name
  if (showNameSetFields.value) {
    await userInfoStore.createUser(newAuditorId.value, customName.value)
    showNameSetFields.value = false
  }
  await auditorStore.addAuditor({ iamId: newAuditorId.value })
  auditorModalOpen.value = false
  newAuditorId.value = ''
}

async function acceptDeleteAuditor () {
  console.log(selectedAuditor.value)
  await auditorStore.removeAuditor(selectedAuditor.value.user_id)
  deleteModalOpen.value = false
  selectedAuditor.value = {}
}

function cancelModal () {
  // hide the dialogs
  deleteModalOpen.value = false
  auditorModalOpen.value = false
  // reset all of these dialog variables to their defaults
  newAuditorId.value = ''
  newAuditorName.value = ''
  customName.value = ''
  formatErrorFound.value = false
  showNameSetFields.value = false
  canAddNewAuditor.value = false
}

function disableAddButton () {
  // if we are adding a new user, make sure their first and last name are set.
  if (showNameSetFields.value) {
    return !canAddNewAuditor.value || customName.value.length === 0
  } else {
    return !canAddNewAuditor.value
  }
}

async function requestNameFromIamId () {
  if (IamIdRegex.test(newAuditorId.value)) {
    formatErrorFound.value = false
    const nameJson = await getNameForIamId(newAuditorId.value)
    // if it is a string, then it must have been an error
    if (typeof nameJson === 'string') {
      newAuditorName.value = nameJson
      if (nameJson === notFoundMessage) {
        // if it was a not-found error, let them click 'Add' to create the person in the db
        canAddNewAuditor.value = true
        showNameSetFields.value = true
      } else {
        // if it's an unexpected error, keep the `Add` button disabled.
        canAddNewAuditor.value = false
      }
    } else {
      // if it wasn't an error, display the name that was found
      newAuditorName.value = `${nameJson.name}`
      canAddNewAuditor.value = true
    }
  } else {
    formatErrorFound.value = true
    canAddNewAuditor.value = false
    newAuditorName.value = ''
  }
}
</script>

<style scoped>
.font-size-xlg {
  font-size: 1.6rem;
}

.fit-content {
  width: 9rem;
  min-width: fit-content;
}
</style>
