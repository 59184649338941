import { defineStore } from 'pinia'
import apiCall from '../lib/api'
import { useAllRolesStore } from './allRolesStore.js'

export const useAuditorStore = defineStore('auditor', {
  state: () => {
    return {
      auditorList: []
    }
  },
  actions: {
    async addAuditor ({ iamId }) {
      const response = await apiCall(`/api/auditor/${iamId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        showToast: true,
        loadingMessage: 'Adding Auditor...'
      })
      if (response.ok) {
        const rolesStore = useAllRolesStore()
        await rolesStore.getAllUserRoles()
      }
    },
    async removeAuditor (idToRemove) {
      const response = await apiCall(`/api/auditor/${idToRemove}`, {
        method: 'DELETE',
        showToast: true,
        loadingMessage: 'Removing Auditor...'
      })
      if (response.ok) {
        const rolesStore = useAllRolesStore()
        await rolesStore.getAllUserRoles()
      }
    }
  }
})
