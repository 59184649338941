import apiCall from '../lib/api'

export const notFoundMessage = 'ID not found in the AMPS database. ' +
  'Specify a temporary name and click \'Add\' to preemptively assign them permissions anyway if you are sure the ID is correct.'

export default async function (iamId) {
  const url = `/api/user/lookup/${iamId}`
  const response = await apiCall(url, {
    loadingMessage: 'Looking up user...',
    showToast: true
  })
  if (response.ok) {
    const { data } = await response.json()
    return data
  } else if (response.status === 404) {
    return notFoundMessage
  } else {
    return 'Error looking up name'
  }
}
