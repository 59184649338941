<template>
  <div v-if="userInfoStore.name" class="grid">
    <a href="/signout">Sign Out</a>
    <span>|</span>
    <span>{{name}}<BIconPersonCircle v-if="isCes" class="ml-2"></BIconPersonCircle></span>
  </div>
  <a v-else href="/signin">Sign In</a>
</template>

<script setup>
import { BIconPersonCircle } from 'bootstrap-icons-vue'
import { storeToRefs } from 'pinia'

import { useUserInfoStore } from '../stores/userInfoStore.js'

const userInfoStore = useUserInfoStore()
const { name } = storeToRefs(userInfoStore)
const isCes = process.env.VUE_APP_ORG === 'CES' // will only show the person circle for the CES page since the BYU branding already does it automatically
</script>

<style>
.grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 0.5rem;
  align-items: center;
}
</style>
