import { useUserInfoStore } from './stores/userInfoStore.js'

// this value should match the variable with the same name in src/server/setup.js
export const CLIENT_MIDDLEWARE_COOKIE_NAME = 'user_info'

/**
 * Returns the okta cookie as a stringified JSON object
 * @returns {string|boolean}
 */
export function getSessionCookie () {
  if (document.cookie.split(';').some(c => c.trim().startsWith(CLIENT_MIDDLEWARE_COOKIE_NAME + '='))) {
    const cookie = document.cookie.split(';').find(c => c.trim().startsWith(CLIENT_MIDDLEWARE_COOKIE_NAME + '='))
    if (cookie) {
      const asStr = decodeURIComponent(cookie.trim()).substr(CLIENT_MIDDLEWARE_COOKIE_NAME.length + '=s:'.length)
      if (asStr === undefined) return false
      return asStr
    }
  }
}

let resolveFn = null
let rejectFn = null
export const initializationPromise = new Promise((resolve, reject) => {
  resolveFn = resolve
  rejectFn = reject
})

export function authentication (signedInCallback, notSignedInCallback) {
  document.addEventListener('DOMContentLoaded', async e => {
    console.log('Loaded!')

    const cookie = getSessionCookie()

    if (cookie) {
      if (signedInCallback) {
        console.log('signed in callback')
        const { initializeUser } = useUserInfoStore()
        await initializeUser(cookie)
        resolveFn(true)
        signedInCallback(cookie)
      } else if (notSignedInCallback) {
        console.log('not signed in callback but has a cookie')
        notSignedInCallback()
      }
    } else if (notSignedInCallback) {
      console.log('not signed in callback')
      rejectFn('Not signed in')
      notSignedInCallback()
    }
  })
}
