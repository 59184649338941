import { defineStore } from 'pinia'
import apiCall from '../lib/api.js'

export const useDomainStore = defineStore('domain', {
  state: () => {
    return {
      domainList: []
    }
  },
  getters: {
    domainListIsEmpty: (state) => {
      return () => state.domainList.length === 0
    },
    domainById: (state) => {
      return (domainId) => {
        const foundDomain = state.domainList.find(domain => domain.domainId === domainId)
        if (foundDomain) {
          return foundDomain
        } else {
          return {}
        }
      }
    }
  },
  actions: {
    async getDomains () {
      console.log('getting domains')
      const response = await apiCall('/api/domains', {
        showToast: true,
        loadingMessage: 'Loading...'
      })
      if (response.ok) {
        const data = await response.json()
        this.domainList = data.data.map((domain) => {
          return {
            domainName: domain.domain_name,
            domainId: domain.domain_id,
            domainDescription: domain.domain_description
          }
        })
      }
    },
    async resetDomains () {
      this.domainList = []
    },
    async addDomain ({ domainName, domainDescription }) {
      const reqBody = JSON.stringify({ domain_name: domainName, domain_description: domainDescription })
      await apiCall('/api/domains', {
        method: 'POST',
        body: reqBody,
        headers: {
          'Content-Type': 'application/json'
        },
        showToast: true,
        loadingMessage: 'Adding domain...'
      })
      await this.getDomains()
    },
    async removeDomain (idToRemove) {
      console.log(idToRemove)
      await apiCall(`/api/domains/${idToRemove}`, {
        method: 'DELETE',
        showToast: true,
        loadingMessage: 'Removing domain...'
      })
      const indexToRemove = this.domainList.findIndex(({ domainId }) => domainId === idToRemove)
      if (indexToRemove >= 0) {
        this.domainList.splice(indexToRemove, 1)
      }
    },
    async updateDomain (domain) {
      const indexToChange = this.domainList.findIndex(({ domainId }) => domainId === domain.domainId)
      this.domainList[indexToChange] = domain
      await apiCall(`/api/domains/${domain.domainId}`, {
        method: 'PUT',
        body: JSON.stringify({
          domain_name: domain.domainName,
          domain_description: domain.domainDescription
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        showToast: true,
        loadingMessage: 'Updating...'
      })
      await this.getDomains()
    }
  }
})
