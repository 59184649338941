<template>
  <Teleport to="body">
    <Transition name="modal">
      <div v-if="modelValue" class="modal-mask" @click="$emit('clickOutside')">
        <div class="modal-wrapper">
          <div class="modal-container" :style="style" @click.stop>
            <!--onsubmit prevents page from refreshing-->
            <form onsubmit="return false" @submit.prevent="$emit('submit', $event)" novalidate>
              <div class="modal-header">
                <slot name="header"></slot>
              </div>
              <div class="modal-body">
                <slot name="body"></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button type="button" class="btn btn-light modal-default-button" @click="$emit('update:modelValue', false)">
                    Close
                  </button>
                </slot>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { toRefs } from 'vue'
defineEmits(['update:modelValue', 'clickOutside', 'submit']) // eslint-disable-line no-undef

const props = defineProps(['modelValue', 'style']) // eslint-disable-line no-undef
const { modelValue, style } = toRefs(props)
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  /*width: 500px;*/
  min-width: 30vw;
  max-width: 70vw;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-height: 90vh;
  overflow: hidden;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  overflow: auto;
  max-height: 60vh;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  /*transition: opacity 0.5s ease;*/
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}
</style>
