<template>
  <div class="toast-container">
    <TransitionGroup name="toast">
      <div class="toast" v-for="toast in toasts" :key="toast.id">
        <div class="toast-header d-flex">
          <BIconCheckCircleFill class="text-success mr-2" v-if="toast.style === 'success'"/>
          <BIconExclamationCircleFill class="text-danger mr-2" v-if="toast.style === 'error'"/>
          <strong class="me-auto flex-grow-1">{{toast.title}}</strong>
          <small>{{ toast.time }}</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" @click="toastStore.removeToast(toast)">
            <BIconXLg class="mb-1"/>
          </button>
        </div>
        <div class="toast-body">
          <template v-if="toast.showSpinner">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </template>
          <span>
            {{toast.body}}
          </span>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useToastStore } from '../stores/toastStore.js'

const toastStore = useToastStore()
const { toasts } = storeToRefs(toastStore)

</script>

<style scoped>
.toast-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  min-width: 300px;
}
.toast {
  opacity: 1;
  max-height: 300px;
  min-width: 300px;
}

.btn-close {
  border: 0;
  background: unset;
  outline: #ddd;
}

.toast-move,
.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s ease;
}
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.toast-leave-active {
  position: absolute;
}
</style>
