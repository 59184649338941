<template>
  <div>
    <template v-if="isGlobalAdmin || isAuditor">
      <GlobalAdminList :readOnly="!isGlobalAdmin"/>
      <AuditorList :readOnly="!isGlobalAdmin"/>
    </template>
    <template v-else>
      <!-- Display Nothing - domain admins shouldn't be able to see this information -->
    </template>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from '../stores/userInfoStore.js'
import AuditorList from '../components/AuditorList.vue'
import GlobalAdminList from '../components/GlobalAdminList.vue'

const userInfoStore = useUserInfoStore()
const { isGlobalAdmin, isAuditor } = storeToRefs(userInfoStore)
</script>
<style>
</style>
