<template>
  <div v-if="showBanner" class="devBanner">
    <BIconTools class="mx-4"/>
    <span class="mx-4">{{ title }}</span>
    <BIconTools class="mx-4"/>
  </div>
</template>

<script setup>
import { BIconTools } from 'bootstrap-icons-vue'
const showBanner = process.env.VUE_APP_STAGE_LEVEL === 'dev' || process.env.VUE_APP_STAGE_LEVEL === 'cpy'
let title = 'DEVELOPMENT'
if (process.env.VUE_APP_STAGE_LEVEL === 'cpy') {
  // BYU cpy environment is mapped to the Sandbox APIs, so we display in the banner that it's sandbox
  title = 'SANDBOX'
}
</script>
<style>
.devBanner {
  width: 100%;
  height: 35px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  position: sticky;
  top: 0;
  z-index: 9998; /* less than the z-index for the loading overlay */
  background-color: #ffb700;
}
</style>
