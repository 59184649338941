<template>
  <select v-model="reportDomain" class="form-control">
    <option v-if="isDomainAdmin" value="" selected hidden disabled>No Domain Selected</option>
    <option v-else value="" selected>All Domains</option>
    <option v-for="domain in domains" v-bind:key="domain.domainId" :value="domain.domainId">{{ domain.domainName }}</option>
  </select>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import { useDomainAdminStore } from '@/stores/domainAdminStore'
import { useDomainStore } from '@/stores/domainStore'
import { useAuditStore } from '@/stores/auditStore'
import { useUserInfoStore } from '@/stores/userInfoStore'
import { ref, watch } from 'vue'

const domainAdminStore = useDomainAdminStore()
const auditStore = useAuditStore()
const domainStore = useDomainStore()
const userStore = useUserInfoStore()

const { userDomains } = storeToRefs(domainAdminStore)
const { reportDomain } = storeToRefs(auditStore)
const { domainList } = storeToRefs(domainStore)
const { isDomainAdmin } = storeToRefs(userStore)
const domains = ref([])

// watch either userDomains or domainList (depending on whether the user is a domain admin or not)
//  to keep the domain list updated
watch(isDomainAdmin.value ? userDomains : domainList, async () => {
  domains.value = isDomainAdmin.value ? userDomains.value : domainList.value
})

</script>
