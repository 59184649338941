<template>
  <div class="d-flex" style="white-space: nowrap;">
    <input class="w-50 mr-1" v-model="userInput" type="text" placeholder="Search by Client ID">
    <button @click="filterApps" class="page-link mr-1">Search</button>
    <button @click="reportApplication=''; reportAppDescription=''" class="page-link">All Applications</button>
  </div>
  <div class="mt-1" v-if="showSearch">
    <ul class="p-0" v-if="applicationsList.length > 0" style="list-style-type: none; padding: 0; width: fit-content">
      <li v-for="application in applicationsList" :key="application.id" class="page-link" style="cursor: pointer" @click="selectApp(application)">
        {{ application.clientId }} ({{ application.name }})
      </li>
    </ul>
    <div v-else>No applications with a matching client ID.</div>
  </div>
</template>

<script setup>

import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useApplicationsStore } from '@/stores/applicationsStore'
import { useAuditStore } from '@/stores/auditStore'

const auditStore = useAuditStore()
const applicationsStore = useApplicationsStore()
const route = useRoute()

const { reportApplication, reportAppDescription } = storeToRefs(auditStore)
const { applicationsList } = storeToRefs(applicationsStore)

const userInput = ref('') // used to search by Client ID
const showSearch = ref(false)

watch(() => route.path, async () => {
  reportApplication.value = ''
  reportAppDescription.value = ''
}, { immediate: true })

async function filterApps () {
  await applicationsStore.getApplications({ pageSize: 10, nextIdentifier: 0, clientId: userInput.value })
  showSearch.value = true
}

function selectApp (application) {
  reportApplication.value = application.id
  showSearch.value = false
  userInput.value = ''
  reportAppDescription.value = `${application.clientId} (${application.name})`
}
</script>
