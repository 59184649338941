<template>
  <div>
<!--  Note that this page shouldn't ever really display (see the router)  -->
    Welcome {{ name }}!
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from '../stores/userInfoStore.js'

const userInfoStore = useUserInfoStore()
const { name } = storeToRefs(userInfoStore)
</script>
<style>
</style>
