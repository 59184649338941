import { defineStore } from 'pinia'
import apiCall from '../lib/api'
import { useGlobalAdminStore } from './globalAdminStore.js'
import { useAuditorStore } from './auditorStore'
import { useDomainAdminStore } from './domainAdminStore.js'

export const useAllRolesStore = defineStore('allRoles', {
  state: () => ({
    allRoles: []
  }),
  actions: {
    async getAllUserRoles () {
      const url = '/api/globalAdmin/getAllUsers'
      const response = await apiCall(url, {
        loadingMessage: 'Loading...',
        showToast: true
      })
      if (response.ok) {
        const { data } = await response.json()
        if (data && Array.isArray(data)) {
          this.allRoles = data
          const globalAdminStore = useGlobalAdminStore()
          globalAdminStore.globalAdminList = data.filter(user => user.is_global_admin)
          const auditorStore = useAuditorStore()
          auditorStore.auditorList = data.filter(user => user.is_auditor)
          const domainAdminStore = useDomainAdminStore()
          domainAdminStore.domainAdminList = data.filter(user => user.is_domain_admin)
        } else {
          this.allRoles = []
        }
      } else {
        this.allRoles = []
      }
    }
  }
})
